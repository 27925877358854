export const paths = {
  USERS: "users",
  IPS: "ips",
  EQUIPMENT: "equipments",
  EQUIPMENTCATEGORY: "equipment-categories",
  BOOKINGS: "bookings",
  METABOOKINGS: "meta-bookings",
  ROOMS: "rooms",
  ZONES: "zones",
  ANIMALS: "animals",
  BOOKINGROOM:"bookings/room",
  CHECKIN: "check-in",
  NEWPASSWORD: "auth/change-password",
  USERMETABOOKING: "meta-bookings/user",
  BOOKINGSBYDATE: "bookings/date-filter",
  RESETPASSWORD:"auth/reset-password/user",
};
