/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

//middleware
import Axios from "axios";
import { faArrowLeft, faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form } from "react-bootstrap";
import { produce } from "immer";
import Swal from "sweetalert2";
import { SwalError } from "../../../../utils/constants";

//utils
import { create, getAll, getById, update } from "../../../../utils/requests/dynamicReq";
import { selectZonesByLocation } from "../../../../utils/auxiliar/roomsFilters";
import { paths } from "../../../../utils/paths";
import { IRoom } from "../../../../interfaces/IRoom";
import { validate } from "./validations";
import { IAnimal } from "../../../../interfaces/IAnimal";
import { locationKey } from "../../../../enum/LocationType";

export const ManageRoom: FC = () => {
  const history = useHistory();
  const { id }: any = useParams();
  const { ZONES, ANIMALS, ROOMS } = paths;

  const [isCreate, setIsCreate] = useState(false);

  //selects
  const [animals, setAnimals] = useState<IAnimal[]>([]);
  const [chosenAnimals, setChosenAnimals] = useState<IAnimal[]>([]);
  const [zones, setZones] = useState([]);
  const [rawZones, setRawZones] = useState([]);

  //form
  const [active, setActive] = useState(true);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [location, setLocation] = useState("");
  const [zone, setZone] = useState("");

  const [message, setMessage] = useState("");

  const isMountedRef = useRef(false);
  const source = Axios.CancelToken.source();

  useEffect(() => {
    isMountedRef.current = true;
    const { pathname }: any = history.location;
    pathname.includes("crear") ? setIsCreate(true) : loadEditData();
    loadDefaultData();
    return () => {
      isMountedRef.current = false;
      source.cancel();
    };
  }, [isCreate]);

  const loadDefaultData = async () => {
    try {
      const response = await getAll(ZONES, source);
      isCreate && loadDefaultAnimals();
      if (isMountedRef.current) {
        setRawZones(response);
        handleLocationAndZone(response[0]?.location, response);
      }
    } catch (error) {
      console.log("HTTP call cancelled");
    }
  };

  const loadDefaultAnimals = async () => {
    try {
      const animals = await getAll(ANIMALS, source);
      console.log(animals);
      if (isMountedRef.current) {
        setAnimals(animals);
      }
    } catch (error) {
      console.log("HTTP call cancelled");
    }
  };

  const loadEditData = async () => {
    try {
      let response = await getById(ROOMS, id, source);
      let animals = await getAll(ANIMALS, source);
      isMountedRef.current && build(response, animals);
    } catch (error) {
      console.log("HTTP call cancelled");
    }
  };

  const build = (response: any, allAnimals: IAnimal[]) => {
    const { name, description, zone, active, animals } = response;
    setName(name);
    setDescription(description);
    setZone(zone?.id);
    setLocation(zone?.location);
    setActive(active);
    setChosenAnimals(animals);

    setTimeout(() => editAnimals(animals, allAnimals), 450);
  };

  const editAnimals = (choosenAnimals: any[], animals: IAnimal[]) => {
    setAnimals(animals);

    // eslint-disable-next-line array-callback-return
    choosenAnimals.map((r: any) => {
      setAnimals((state) => produce(state, (drafState) => drafState.filter(({ id }) => id !== r.id)));
    });
  };

  //--------------------------------

  // HANDLE ANIMALS
  const addAnimal = (animal: any) => {
    animal = JSON.parse(animal);

    //add animal
    setChosenAnimals((state) =>
      produce(state, (drafState) => {
        drafState.push(animal);
      })
    );

    //remove animal from list
    setAnimals((state) => produce(state, (drafState) => drafState.filter(({ id }) => id !== animal.id)));
  };

  const removeAnimal = (animal: any) => {
    // remove animal from list
    setChosenAnimals((state) => produce(state, (drafState) => drafState.filter(({ id }) => id !== animal.id)));

    // add animal to list
    setAnimals((state) =>
      produce(state, (drafState) => {
        drafState.push(animal);
      })
    );
  };

  //--------------------------------
  //HANDLE LOCATION

  const handleLocationAndZone = (value: string, rawZones: any[]) => {
    setLocation(value);
    let zones = selectZonesByLocation(rawZones, value);
    setZones(zones);
    setZone(zones[0]?.id);
  };

  //--------------------------------

  const handleSubmit = () => {
    const room: IRoom = buildToSend();
    const notValid = validate(room, chosenAnimals);
    if (notValid) {
      setMessage(notValid);
      setTimeout(() => setMessage(""), 4000);
      return;
    }
    let question = isCreate ? "crear una" : "editar esta";
    Swal.fire({
      icon: "question",
      text: `¿Quieres ${question} sala?`,
      showCancelButton: true,
      confirmButtonText: "Si",
      cancelButtonText: "No",
    }).then(({ isConfirmed }) => {
      isConfirmed && handleConfirmation(room);
    });
  };

  const handleConfirmation = (room: IRoom) => {
    let createOrUpdate = isCreate ? create(ROOMS, room, source) : update(ROOMS, id, room, source);
    createOrUpdate
      .then(async ({ id: roomId }) => {
        let createOrUpdateText = isCreate ? "creada" : "editada";
        Swal.fire({
          icon: "success",
          text: `Sala ${createOrUpdateText} correctamente.`,
        });

        isMountedRef.current && setIsCreate(false);
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          text: SwalError,
        });
      });
  };

  const buildToSend = (): IRoom => ({
    name,
    description,
    active,
    zoneId: +zone,
    animalIds: selectAnimalsid(),
  });

  const selectAnimalsid = () => {
    let animalIds: number[] = [];
    chosenAnimals.map(({ id }) => animalIds.push(id!));
    return animalIds;
  };

  const title = isCreate ? "Nueva Sala" : "Editar Sala";

  return (
    <>
      <div className="container-fluid pl-4">
        <div className="row mb-4">
          <div className="col-sm-6 col-12 d-flex justify-content-between">
            <h2>{title}</h2>
            <button className="baseButton" onClick={() => history.goBack()}>
              <FontAwesomeIcon icon={faArrowLeft} />
              Atrás
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6 col-12 whiteDiv p-4">
            <div className="form-row">
              <div className="col-sm-6 col-12">
                <h6>*Nombre de sala</h6>
                <input
                  type="text"
                  className="form-control"
                  value={name}
                  onChange={({ target: { value } }) => {
                    setName(value);
                  }}
                />
              </div>
              <div className="col-sm-6 col-12">
                <h6>Habilitada</h6>
                <Form>
                  <Form.Check
                    type="switch"
                    id="custom-switch"
                    label={active ? "Sí" : "No"}
                    checked={active}
                    onChange={() => {
                      setActive(!active);
                    }}
                  />
                </Form>
              </div>
            </div>
            <div className="form-row mt-2">
              <div className="col-sm-6 col-12">
                <h6>*Instalación</h6>
                <select
                  className="form-control"
                  value={location}
                  onChange={({ target: { value } }) => handleLocationAndZone(value, rawZones)}
                >
                  <option value={locationKey.ceca}>{locationKey.ceca}</option>
                  <option value={locationKey.medicina}>{locationKey.medicina}</option>
                </select>
              </div>
              <div className="col-sm-6 col-12">
                <h6>*Zona</h6>
                <select
                  className="form-control"
                  data-spy="scroll"
                  value={zone}
                  onChange={({ target: { value } }) => {
                    setZone(value);
                  }}
                >
                  {zones?.map(({ name, id }) => (
                    <option key={id} value={id}>
                      {name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="form-row mt-2">
              <div className="col-sm-12 col-12">
                <h6>Descripción</h6>
                <textarea
                  className="form-control"
                  value={description}
                  onChange={({ target: { value } }) => {
                    setDescription(value);
                  }}
                />
              </div>
            </div>
            <div className="form-row mt-2">
              <div className="col-12">
                <h6>Animales</h6>
                <select
                  className="form-control input"
                  value={""}
                  onChange={({ target: { value } }) => {
                    addAnimal(value);
                  }}
                >
                  <option value={""}>Seleccionar animales</option>
                  {animals?.map((i: any) => (
                    <option key={i.id} value={JSON.stringify(i)}>
                      {`${i.name}`}
                    </option>
                  ))}
                </select>
                {chosenAnimals?.map((animal) => (
                  <span
                    key={animal.id}
                    style={{ cursor: "pointer" }}
                    className="badge bg-lightgrey mr-1 mt-3  "
                    onClick={() => removeAnimal(animal)}
                  >
                    {`${animal.name}`} X
                  </span>
                ))}
              </div>
            </div>

            <div className="form-row mt-4">
              <div className="col-12 d-flex justify-content-center">
                <div>
                  <button className="button-search" onClick={handleSubmit}>
                    <FontAwesomeIcon className="mr-2" icon={faSave} />
                    Guardar
                  </button>
                </div>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-12 text-center text-danger">
                <span>{message}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
