/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useEffect, useRef, useState } from "react";

//middleware
import Axios from "axios";
import Swal from "sweetalert2";
import { useHistory, useParams } from "react-router";

//interfaces
import { IAnimal } from "../../interfaces/IAnimal";
import { IZone } from "../../interfaces/IZone";
import { IEquipment } from "../../interfaces/IEquipment";
import { IUser } from "../../interfaces/IUser";
import { IBookingRedux } from "../../interfaces/IBookingRedux";
import { ICalendarBooking } from "../../interfaces/ICalendarBooking";
import { IStore } from "../../interfaces/IStore";
import { IBookingToSend } from "../../interfaces/IBookingToSend";
import { IRoomBookingForm } from "../../interfaces/IRoom";

//reqs
import { create, getAll, getById, update } from "../../utils/requests/dynamicReq";
import { getAvailableEquipment } from "../../utils/requests/equipmentReq";

//constants
import { paths } from "../../utils/paths";
import { SixMonthsAfter, SwalError } from "../../utils/constants";
import { ROUTES } from "../../utils/routes";

//misc
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import TextField from "@material-ui/core/TextField";
import { Autocomplete } from "@material-ui/lab";
import { CircularProgress, FormControl, Select } from "@material-ui/core";

//datepicker
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import "react-datepicker/dist/react-datepicker.css";

//redux
import { useDispatch, useSelector } from "react-redux";
import { SetBookingAction, UnsetBookingAction } from "../../redux/actions";

//utils
//prettier-ignore
import {addBooking, draftEraseBooking, highlightBooking, minBookingDate, removeBooking } from "../../utils/auxiliar/bookingsFilters";
import { filterRooms } from "../../utils/auxiliar/roomsFilters";
import { dateToDDBB, utcToLocalTime } from "../../utils/auxiliar/dates";
import { userIds } from "../../utils/auxiliar/userFilters";
import {
  availabilityCriteria,
  checkIfStillAvailable,
  equipmentIds,
} from "../../utils/auxiliar/equipmentFilters";

//validations
import {
  IMetabookingSHORT,
  validateBooking,
  validateMetaBooking,
  validateOverlap,
} from "./validations";

//hooks
import { useDelete } from "../../utils/hooks/useDelete";
import { locationKey } from "../../enum/LocationType";

registerLocale("es", es);

interface IProps {
  roomId: number | undefined;
  loadRoomAvailability(roomId?: number): void;
  setRoomId(id: number | undefined): void;
  setDate(date: Date): void;
  setBookings(bookings: any): void;
  bookings: ICalendarBooking[];
}

export const BookingForm = ({
  roomId,
  loadRoomAvailability,
  setRoomId,
  setDate,
  setBookings,
  bookings,
}: IProps) => {
  const { ANIMALS, ZONES, ROOMS, USERS, EQUIPMENT, METABOOKINGS, BOOKINGS } = paths;

  const { metaRouteId, bookingId }: any = useParams();

  const dispatch = useDispatch();

  //prettier-ignore
  let { id,  isAdmin, name} = useSelector(({ user }: IStore) => user as IUser);
  let { ipId: RxIpId } = useSelector(({ user }: IStore) => user as IUser);

  let {
    RxZoneId,
    RxRoomId,
    RxAdditionalInfo,
    RxBookingUsers,
    RxExternalUser1,
    RxExternalUser2,
  } = useSelector(({ booking }: IStore) => booking as IBookingRedux);

  const { deleteIt } = useDelete();

  const history = useHistory();

  const isMountedRef = useRef(false);
  const repeatBooking = useRef(false);

  const source = Axios.CancelToken.source();

  const [animals, setAnimals] = useState<IAnimal[]>([]);
  const [animalId, setAnimalId] = useState<number>();

  const [rawZones, setRawZones] = useState<IZone[]>([]);
  const [displayZones, setDisplayZones] = useState<IZone[]>([]);
  const [zoneId, setZoneId] = useState<number | undefined>(RxZoneId);

  const [rawRooms, setRawRooms] = useState<IRoomBookingForm[]>([]);
  const [displayRooms, setDisplayRooms] = useState<IRoomBookingForm[]>([]);

  const [addedBooking, setAddedBooking] = useState(false);
  const [bookingToEdit, setBookingToEdit] = useState(false);

  const [equipments, setEquipments] = useState<IEquipment[]>([]);

  const [users, setUsers] = useState<IUser[]>([]);
  const [chosenUser, setChosenUser] = useState<IUser>();

  const [externalUser, setExternalUser] = useState("");

  const [loadingUsers, setLoadingUsers] = useState(false);
  const [loadingEquipment, setLoadingEquipment] = useState(false);

  const [isCreate, setIsCreate] = useState(false);

  //message
  const [message, setMessage] = useState("");
  const [equipmentMessage, setEquipmentMessage] = useState("");

  //to POST => metaBookings
  const [userId, setUserId] = useState<number>(id);
  const [ipId, setIpId] = useState<number | undefined>(RxIpId);
  const [location, setLocation] = useState("");

  //to POST => bookings
  const [metaBookingId, setMetaBookingId] = useState<number>(+metaRouteId);
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const [additionalInfo, setAdditionalInfo] = useState(RxAdditionalInfo);
  const [externalUser1, setExternalUser1] = useState(RxExternalUser1);
  const [externalUser2, setExternalUser2] = useState(RxExternalUser2);
  const [bookingUsers, setBookingUsers] = useState<IUser[]>([]);
  const [bookingEquipment, setBookingEquipment] = useState<IEquipment[]>([]);

  useEffect(() => {
    isMountedRef.current = true;
    const { pathname } = history.location;
    pathname.includes("nueva") && setIsCreate(true);
    loadData();

    return () => {
      isMountedRef.current = false;
      source.cancel();
      dispatch(UnsetBookingAction());
    };
  }, [metaRouteId]);

  const loadData = async () => {
    try {
      const animals = await getAll(ANIMALS, source);
      const zones = await getAll(ZONES, source);
      const rooms = await getAll(ROOMS, source);
      if (isMountedRef.current) {
        !!+metaRouteId && loadMetaData(zones, rooms);
        setAnimals(animals);
        setRawZones(zones);
        setRawRooms(rooms);
      }
    } catch (error) {
      console.log("HTTP call cancelled");
    }
  };

  const loadMetaData = async (zones: IZone[], rooms: IRoomBookingForm[]) => {
    try {
      //prettier-ignore
      const { location, animal, user, ip } = await getById(METABOOKINGS, metaRouteId, source );
      //redirecting user if it´s not his/her booking
      if (user.id !== id && !isAdmin)
        return history.push(ROUTES.Common.MetaBookings);

      if (isMountedRef.current) {
        const { pathname } = history.location;
        pathname.includes("gestionar") && loadEditData(animal.id, rooms, user);

        setLocation(location);
        setDisplayZones(zones?.filter((zone) => zone.location === location));
        setAnimalId(animal.id);
        setChosenUser(user);
        setIpId(ip.id);
        setUserId(user.id);

        RxZoneId && setZoneId(RxZoneId);
        RxAdditionalInfo && setAdditionalInfo(RxAdditionalInfo);
        RxExternalUser1 && setExternalUser1(RxExternalUser1);
        RxExternalUser2 && setExternalUser2(RxExternalUser2);
        RxBookingUsers.length && setBookingUsers(RxBookingUsers);
        if (RxRoomId) {
          loadRoomAvailability(RxRoomId);
          setRoomId(RxRoomId);
        }
      }
    } catch (error) {
      console.log("HTTP call cancelled");
    }
  };

  //prettier-ignore
  const loadEditData = async (animalId: number, rooms: IRoomBookingForm[], mainUser: IUser) => {
    setBookingToEdit(true);
    try {
      const booking = await getById(BOOKINGS, bookingId, source );

      if (isMountedRef.current) {
        setZoneId(booking.room.zone.id);
        setDisplayRooms(filterRooms(rooms, animalId, booking.room.zone.id));
        setRoomId(booking.room.id);
        setAdditionalInfo(booking.additionalInfo);
        setDate(new Date(utcToLocalTime(booking.startDate)));
        setStartDate(new Date(utcToLocalTime(booking.startDate)));
        setEndDate(new Date(utcToLocalTime(booking.endDate)));
        setExternalUser1(booking.externalUser1);
        setExternalUser2(booking.externalUser2);
        setBookingEquipment(booking.equipments);

        let draftBookings: any = await loadRoomAvailability(booking.room.id!);
        highlightBooking(setBookings,draftBookings, booking);
        //removing mainUser from "acompañantes"
        //prettier-ignore
        setBookingUsers(booking.users.filter((user: IUser)=>user.id !== mainUser?.id));
      }
    } catch (error) {
      console.log("HTTP call cancelled");
    }
  };

  const loadUsers = async () => {
    if (users.length) return;
    try {
      setLoadingUsers(true);
      const users = await getAll(USERS, source);
      if (isMountedRef.current) {
        setLoadingUsers(false);
        setUsers(users.filter((user: IUser) => user.id !== chosenUser?.id));
      }
    } catch (error) {
      if (isMountedRef.current) {
        console.log(error);
        setLoadingUsers(false);
      }
    }
  };

  const loadEquipment = async () => {
    setEquipmentMessage("");
    if (!startDate || !endDate || !location) {
      //prettier-ignore
      setEquipmentMessage("Complete INSTALACIÓN y FECHA para ver instrumental disponible.");
      return;
    }
    !equipments.length && equipmentsAvailability(startDate, endDate);
  };

  const equipmentsAvailability = async (startDate: Date, endDate: Date) => {
    try {
      setLoadingEquipment(true);
      const criteria = availabilityCriteria(startDate, endDate, location, bookingId);
      //prettier-ignore
      const equipments = await getAvailableEquipment(`${EQUIPMENT}/available`, criteria, source);
      if (isMountedRef.current) {
        setLoadingEquipment(false);
        setEquipments(equipments);
        return equipments;
      }
    } catch (error) {
      if (isMountedRef.current) {
        console.log(error);
        setLoadingEquipment(false);
      }
    }
  };

  const handleMainStartDate = (date: Date) => {
    if (!isCreate && bookingToEdit)
      draftEraseBooking(setBookings, bookingId, setBookingToEdit);

    setEquipments([]);
    setDate(date);
    setStartDate(date);
    setEndDate(undefined);

    addedBooking && removeBooking(setBookings);
  };

  const handleStartDate = (date: Date) => {
    //if editing, remove booking from caledar before placing a new one
    if (!isCreate && bookingToEdit)
      draftEraseBooking(setBookings, bookingId, setBookingToEdit);

    setEquipments([]);
    setStartDate(date);
    setEndDate(date);

    if (addedBooking) {
      removeBooking(setBookings);
      setAddedBooking(false);
    }
  };

  const handleEndDate = async (date: Date) => {
    if (!isCreate && bookingToEdit)
      draftEraseBooking(setBookings, bookingId, setBookingToEdit);

    setEquipments([]);

    setMessage("");
    if (startDate && date <= startDate) {
      //prettier-ignore
      setMessage("La fecha de finalización no puede ser anterior o igual a la de inicio");
      setTimeout(() => setMessage(""), 4000);
      return;
    }
    setEndDate(date);

    if (addedBooking) {
      removeBooking(setBookings);
      setAddedBooking(false);
    }
    if (startDate) {
      let equipments: IEquipment[] = await equipmentsAvailability(startDate, date);
      //prettier-ignore
      bookingEquipment && checkIfStillAvailable(equipments, bookingEquipment, setBookingEquipment, isMountedRef);

      addBooking(setBookings, name, startDate, date);
      setAddedBooking(true);
    }
  };

  const changeLocation = (value: string) => {
    zoneId && setZoneId(undefined);
    if (roomId) {
      setRoomId(undefined);
      loadRoomAvailability(undefined);
    }

    setLocation(value);
    setDisplayZones(rawZones.filter(({ location }) => location === value));
  };

  const changeAnimal = (value: any) => {
    setAnimalId(+value);
    zoneId && setDisplayRooms(filterRooms(rawRooms, value, zoneId));
  };

  const changeZone = ({ target: { value } }: any) => {
    if (roomId) {
      setRoomId(undefined);
      loadRoomAvailability(undefined);
    }
    setZoneId(value);

    if (animalId) {
      const filteredRooms = filterRooms(rawRooms, animalId, value);
      setDisplayRooms(filteredRooms);
    }
  };

  //submitting data
  const handleSubmit = ({ repeat }: { repeat: boolean }) => {
    repeatBooking.current = repeat;
    submit();
  };

  const submit = () => {
    if (!validate()) return;
    Swal.fire({ icon: "question", text: `¿Quieres crear una nueva reserva?` }).then(
      ({ isConfirmed }) => {
        if (isConfirmed) {
          setAddedBooking(false);
          metaBookingId ? submitBooking() : submitMetabooking();
        }
      }
    );
  };

  const submitMetabooking = async () => {
    const draftMetaBooking = metabooking();
    create(METABOOKINGS, draftMetaBooking, source).then(({ id: metaId }) => {
      setMetaBookingId(metaId);
      submitBooking(metaId);
    });
  };

  const submitBooking = async (newMetaBookingId?: number) => {
    const metaId = metaBookingId || newMetaBookingId;
    const draftBooking = buildBooking(metaId!);

    create(BOOKINGS, draftBooking, source)
      .then(() => {
        Swal.fire({ icon: "success", text: `Reserva creada correctamente.` });
        repeatBooking.current
          ? handleRepeatBooking(metaId!)
          : history.push(`${ROUTES.Common.MetaBookings}/${metaId}`);
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({ icon: "error", text: SwalError });
      });
  };

  const handleRepeatBooking = (metaId: number) => {
    //set redux
    const draftBookingRedux = bookingRedux(metaId!);
    dispatch(SetBookingAction(draftBookingRedux));

    loadRoomAvailability(roomId);
    setStartDate(undefined);
    setEndDate(undefined);
    history.push(`${ROUTES.Common.NewBooking}/${metaId}`);
  };

  //-----------------

  const edit = () => {
    if (!validate()) return;
    Swal.fire({ icon: "question", text: `¿Quieres editar esta reserva?` }).then(
      ({ isConfirmed }) => {
        isConfirmed && editBooking();
      }
    );
  };

  const editBooking = async () => {
    const draftBooking = buildBooking(metaBookingId!);

    update(BOOKINGS, bookingId, draftBooking, source)
      .then(() => history.push(`${ROUTES.Common.MetaBookings}/${metaBookingId}`))
      .catch(() => Swal.fire({ icon: "error", text: SwalError }));
  };

  const buildBooking = (metaId: number): IBookingToSend => ({
    startDate: dateToDDBB(startDate!),
    endDate: dateToDDBB(endDate!),
    roomId: roomId!,
    externalUser1,
    externalUser2,
    additionalInfo,
    metaBookingId: metaId,
    userIds: userIds(bookingUsers, userId),
    equipmentIds: equipmentIds(bookingEquipment),
  });

  const bookingRedux = (metaId: number): IBookingRedux => ({
    RxMetaBookingId: metaId,
    RxZoneId: zoneId,
    RxRoomId: roomId,
    RxAdditionalInfo: additionalInfo,
    RxBookingEquipment: bookingEquipment,
    RxBookingUsers: bookingUsers,
    RxExternalUser1: externalUser1,
    RxExternalUser2: externalUser2,
  });

  const metabooking = (): IMetabookingSHORT => ({
    location,
    ipId,
    userId,
    animalId,
    status: isAdmin ? "confirmed" : "pending",
  });

  //validations
  const validate = () => {
    let valid = true;
    const draftMetabooking = metabooking();

    let notValidMeta = validateMetaBooking(draftMetabooking, roomId);
    let notValidBooking = validateBooking(startDate, endDate);
    let overlap = validateOverlap(bookings, startDate, endDate, bookingId);

    if (!!notValidMeta || !!notValidBooking || !!overlap) {
      setMessage(notValidMeta || notValidBooking || overlap);
      setTimeout(() => setMessage(""), 4000);
      valid = false;
    }
    return valid;
  };
  //-----------------

  return (
    <>
      <div className="form-row">
        <div className="col-12">
          <h5 className="text-center">Formulario de reserva</h5>
        </div>
      </div>
      <div className="form-row mt-2">
        <div className="col-12">
          <h6>Instalación</h6>
          <FormControl
            disabled={!!+metaRouteId}
            size="small"
            fullWidth
            variant="outlined"
          >
            <Select
              native
              value={location}
              onChange={({ target: { value } }: any) => changeLocation(value)}
            >
              <option value="">Seleccione una instalación</option>
              <option value={locationKey.ceca}>{locationKey.ceca}</option>
                  <option value={locationKey.medicina}>{locationKey.medicina}</option>
            </Select>
          </FormControl>
        </div>
      </div>

      <div className="form-row mt-2">
        <div className="col-12">
          <h6>Animal</h6>
          <FormControl
            disabled={!!+metaRouteId}
            size="small"
            fullWidth
            variant="outlined"
          >
            <Select
              native
              value={animalId}
              onChange={({ target: { value } }: any) => changeAnimal(value)}
            >
              <option>Selecciona un animal</option>
              {animals.map(({ id, name }: any) => (
                <option key={id} value={id}>
                  {name}
                </option>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>

      <div className="form-row mt-2">
        <div className="col-12">
          <h6>Zona</h6>
          <FormControl size="small" fullWidth variant="outlined">
            <Select
              native
              value={zoneId}
              onChange={changeZone}
              // disabled={!displayZones.length}
            >
              <option value="">Selecciona una zona</option>
              {displayZones.map(({ id, name }: any) => (
                <option key={id} value={id}>
                  {name}
                </option>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>

      <div className="form-row mt-2">
        <div className="col-12">
          <h6>Sala</h6>
          <FormControl size="small" fullWidth variant="outlined">
            <Select
              native
              value={roomId}
              onChange={({ target: { value } }: any) => {
                startDate && setStartDate(undefined);
                endDate && setEndDate(undefined);
                setRoomId(+value);
                loadRoomAvailability(+value);
              }}
              disabled={!zoneId || !animalId}
            >
              <option value="">Selecciona una sala</option>
              {displayRooms.map(({ id, name }: any) => (
                <option key={id} value={id}>
                  {name}
                </option>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>

      <div className="form-row mt-2">
        <div className="col-12">
          <h6>Breve descripción del Experimento</h6>
          <TextField
            id="additionalInfo"
            variant="outlined"
            multiline
            fullWidth
            rows={4}
            value={additionalInfo}
            onChange={({ target: { value } }) => setAdditionalInfo(value)}
            inputProps={{ maxLength: 150 }}
            helperText="Máx. 150 caracteres."
          />
        </div>
      </div>

      <div className="form-row mt-2">
        <div className="col-6">
          <h6>Fecha</h6>
          <DatePicker
            className="form-control bg-fafafa"
            placeholderText="Día"
            selected={startDate}
            disabled={!roomId}
            onChange={(date: Date) => handleMainStartDate(date)}
            onChangeRaw={(e: any) => e.preventDefault()}
            minDate={minBookingDate(isAdmin)}
            maxDate={new Date(SixMonthsAfter)}
            showPreviousMonths={false}
            // filterDate={(date: Date) => moment(date).day() !== 0}
            dateFormat=" dd/MM/yyyy"
            timeFormat="HH:mm"
            locale="es"
          />
        </div>
        <div className="col-3">
          <h6>Inicio</h6>
          <DatePicker
            className="form-control bg-fafafa"
            selected={startDate}
            disabled={!startDate}
            onChange={(date: Date) => handleStartDate(date)}
            onChangeRaw={(e: any) => e.preventDefault()}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            dateFormat="HH:mm"
            timeCaption="Hora"
            timeFormat="H:mm "
          />
        </div>
        <div className="col-3">
          <h6>Fin</h6>
          <DatePicker
            className="form-control bg-fafafa"
            selected={endDate ? endDate : startDate}
            disabled={!startDate}
            onChange={(date: Date) => handleEndDate(date)}
            onChangeRaw={(e: any) => e.preventDefault()}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            dateFormat="HH:mm"
            timeCaption="Hora"
            timeFormat="H:mm "
          />
        </div>
      </div>

      <div className="form-row mt-2">
        <div className="col-12">
          <h6>Instrumental</h6>
          <Autocomplete
            multiple
            limitTags={2}
            id="equipment"
            size="small"
            options={equipments}
            getOptionLabel={({ name }) => name}
            onChange={(e, value) => setBookingEquipment(value)}
            getOptionSelected={(option, value) => option.id === value.id}
            value={bookingEquipment}
            loading={loadingEquipment}
            loadingText={"Cargando instrumental..."}
            renderInput={(params) =>
              //prettier-ignore
              <TextField
                {...params}
                variant="outlined"
                onClick={loadEquipment}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loadingEquipment && <CircularProgress color="inherit" size={20} />}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            }
          />
          <small className="text-danger">{equipmentMessage}</small>
        </div>
      </div>

      {isAdmin && (
        <div className="form-row mt-2">
          <div className="col-12">
            <h6>Responsable de experimento</h6>
            {!!+metaRouteId ? (
              <TextField
                value={`${chosenUser?.name} ${chosenUser?.surname1} ${chosenUser?.surname2}`}
                size="small"
                variant="outlined"
                fullWidth
                disabled
              />
            ) : (
              <Autocomplete
                id="mainUser"
                options={users}
                size="small"
                onClick={loadUsers}
                // value={chosenUser}
                // defaultValue={chosenUser}
                disabled={!!+metaRouteId}
                onChange={(e, value) => {
                  setUserId(value!.id);
                  setIpId(value!.ip!.id);
                }}
                getOptionLabel={(opt) =>
                  `${opt.name} ${opt.surname1} ${opt.surname2}`
                }
                loading={loadingUsers}
                loadingText={"Cargando usuarios..."}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" onClick={loadUsers} />
                )}
              />
            )}
          </div>
        </div>
      )}

      <div className="form-row mt-2">
        <div className="col-12">
          <h6>Acompañante a sala</h6>
          <Autocomplete
            multiple
            limitTags={2}
            // id="users"
            size="small"
            options={users}
            getOptionLabel={(opt) => `${opt.name} ${opt.surname1} ${opt.surname2}`}
            value={bookingUsers}
            onChange={(e, value) => setBookingUsers(value)}
            loading={loadingUsers}
            loadingText={"Cargando usuarios..."}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" onClick={loadUsers} />
            )}
          />

          <small>
            Si no encuentra el nombre en la base de datos, por favor escriba sus
            datos a continuación (hasta dos personas).
          </small>
        </div>
      </div>

      <div className="form-row mt-2">
        <div className="col-12">
          <h6>Nombre completo</h6>
          <div className="d-flex">
            <TextField
              value={externalUser}
              size="small"
              id="externalUser"
              variant="outlined"
              onChange={({ target: { value } }) => setExternalUser(value)}
            />

            <button
              className="button-search"
              onClick={() =>
                !externalUser1
                  ? setExternalUser1(externalUser)
                  : setExternalUser2(externalUser)
              }
            >
              <FontAwesomeIcon className="mr-2" icon={faPlusCircle} />
              Añadir
            </button>
          </div>
          {externalUser1 && (
            <span
              style={{ cursor: "pointer" }}
              className="badge badge-secondary mr-1 mt-3  "
              onClick={() => setExternalUser1("")}
            >
              {externalUser1} X
            </span>
          )}
          {externalUser2 && (
            <span
              style={{ cursor: "pointer" }}
              className="badge badge-secondary mr-1 mt-3  "
              onClick={() => setExternalUser2("")}
            >
              {externalUser2} X
            </span>
          )}
        </div>
      </div>
      <div className="form-row ">
        <div className=" col-12 mt-2 text-danger">
          <span>{message}</span>
        </div>
      </div>
      {isCreate && (
        <div className="form-row mt-2">
          <div className=" col-12 d-flex justify-content-around">
            <button
              className="button-save"
              onClick={() => handleSubmit({ repeat: true })}
              disabled={!!message}
            >
              Guardar y reservar otro día
            </button>
            <button
              className="button-save"
              onClick={() => handleSubmit({ repeat: false })}
              disabled={!!message}
            >
              Guardar reserva y finalizar
            </button>
          </div>
        </div>
      )}

      {!isCreate && (
        <div className="form-row mt-4">
          <div className="col-12 d-flex justify-content-around">
            <button className="button-save" onClick={() => edit()}>
              Actualizar reserva
            </button>
            <button
              className="button-cancel"
              onClick={() =>
                deleteIt(BOOKINGS, bookingId!, ROUTES.Common.MetaBookings, source)
              }
            >
              Cancelar reserva
            </button>
          </div>
        </div>
      )}
    </>
  );
};
