/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useRef, useState } from "react";

//middleware
import Axios from "axios";

import { More } from "../../../components/More/More";
import { getByFilter } from "../../../utils/requests/dynamicReq";
import { paths } from "../../../utils/paths";
import { InboxList } from "../../../components/InboxList/InboxList";
import { IInbox } from "../../../interfaces/IInbox";

export const Inbox: FC = () => {
  const { METABOOKINGS } = paths;

  const isMountedRef = useRef(false);
  const source = Axios.CancelToken.source();

  const [inbox, setInbox] = useState<IInbox[]>([]);
  const [displayInbox, setDisplayInbox] = useState<IInbox[]>([]);

  const [index, setIndex] = useState(6);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    isMountedRef.current = true;
    loadData();
    return () => {
      isMountedRef.current = false;
      source.cancel();
    };
  }, []);

  const loadData = async () => {
    try {
      const response = await getByFilter(METABOOKINGS, "managed", 0, source);
      if (isMountedRef.current) {
        setDisplayInbox(response);
        setInbox(response);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log("HTTP call cancelled");
    }
  };


  return (
    <>
      <div className="container-fluid pl-4">
        <div className="row mb-4">
          <div className="col-12">
            <h2>Buzón de reservas</h2>
          </div>
        </div>

        {/* Se le pasa un array de objetos. Se carga dependiendo si el user es admin o no [TENER EN CUENTA SI ES TÉCNICX]*/}
        <div className="row mt-4">
          <div className="col-12 whiteDiv overFlowAuto">
            <InboxList inbox={displayInbox} index={index} />
            <More
              data={inbox}
              displayData={displayInbox}
              isLoading={isLoading}
              setIndex={setIndex}
              index={index}
            />
          </div>
        </div>
      </div>
    </>
  );
};
