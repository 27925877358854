import { FC, useEffect, useState } from "react";

//css
import "./Login.css";

//middleware
import { useDispatch } from "react-redux";

//images
import logo from "../../assets/images/MARCA_UNIVERSIDAD_BLANCO_TRANS_1.png";

//aux
import { SetUserAction } from "../../redux/actions";
import { login } from "../../utils/requests/dynamicReq";
import { verify } from "jsonwebtoken";
import { jwtPassword } from "../../utils/constants";
import { role } from "../../utils/roles";
import { useHistory, useLocation } from "react-router-dom";

const Login: FC = () => {
  const dispatch = useDispatch();
  let history = useHistory();
  let location = useLocation();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  let { from }: any = location.state || { from: { pathname: "/" } };

  useEffect(() => {}, []);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const { token }: any = await login(email, password);
      //prettier-ignore
      const {userId, userRole, name, surname1, surname2, phone, ipId }: any = verify(token, jwtPassword);
      const user: any = verify(token, jwtPassword);
      console.log(user);

      localStorage.setItem("token", token);
      setLoading(false);
      dispatch(
        SetUserAction({
          token,
          id: userId,
          userRole,
          isAdmin: userRole === role.admin,
          name,
          surname1,
          surname2,
          email,
          phone,
          ipId,
        })
      );
      history.replace(from);
    } catch (error) {
      console.log(error);
      setLoading(false);
      setMessage("Nombre de usuario o contraseña incorrecta.");
      setTimeout(() => {
        setMessage("");
        setPassword("");
      }, 3000);
    }
  };

  const disabled = !email || !password;

  return (
    <>
      <div className="loginContainer">
        <div
          className="cardLogin"
          onKeyPress={({ key }) => {
            if (!disabled && key === "Enter") {
              setMessage("");
              handleSubmit();
            }
          }}
        >
          <img className="loginLogo" src={logo} alt="" />

          <div className="form-group">
            <input
              className="form-control inputLogin mt-4"
              type="text"
              placeholder="Email"
              value={email}
              onChange={({ target: { value } }) => {
                setMessage("");
                setEmail(value);
              }}
            />
          </div>

          <div className="form-group">
            <input
              className="form-control inputLogin"
              type="password"
              placeholder="Contraseña"
              value={password}
              onChange={({ target: { value } }) => {
                setMessage("");
                setPassword(value);
              }}
            />
          </div>
          {message && <span style={{ color: "black" }}>{message}</span>}
          <button
            style={{
              cursor: disabled ? "not-allowed" : "",
            }}
            className="loginButton mt-4"
            disabled={disabled || Boolean(message)}
            onClick={handleSubmit}
          >
            {!loading ? (
              "Login"
            ) : (
              <span className="spinner-border spinner-border-sm" />
            )}
          </button>
        </div>
      </div>
    </>
  );
};

export default Login;
