import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import { ICsvBooking } from "../../interfaces/ICsvBooking";
import logo from "./logouma.jpg";
import { TableContainer } from "./PdfTable/TableContainer";

// Create styles
const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 18,
    fontWeight: 700,
    textAlign: "center",
    textDecoration: "underline",
  },
  subtitle: {
    fontSize: 18,
    textAlign: "left",
    marginBottom: 20,
    marginLeft: 12,
  },
  bulletPoint: {
    fontSize: 12,
    marginBottom: 5,
    marginLeft: 12,
  },
  text: {
    margin: 12,
    fontSize: 10,
    textAlign: "justify",
  },
  table: {
    display: "flex",
    flexDirection: "row",
  },
  logo: {
    marginVertical: 12,
    marginHorizontal: 12,
    width: 100,
  },
  header: {
    fontSize: 12,
    marginBottom: 2,
    textAlign: "center",
    color: "grey",
  },
  separationLine: {
    borderTop: "black",
    borderTopColor: "black",
    borderTopWidth: 2,
    marginLeft: 12,
  },
  row: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  column: {
    flexDirection: "column",
    width: "20%",
  },
});

interface IProps {
  bookings: ICsvBooking[];
  startDate: string;
  endDate: string;
  room: string;
  location: string;
}

// Create Document Component
export const TechnicianPdf = ({ bookings, startDate, endDate, location, room }: IProps) => {

  return (
    <Document>
      {!!bookings && logo && (
        <>
          <Page style={styles.body} size="A4">
            <View>
              <View style={styles.row} wrap>
                <View style={styles.column}>
                  {logo && (
                    <Image style={styles.logo} src={logo} cache={false} />
                  )}
                </View>
              </View>
              <View style={styles.row} wrap>
                <View style={[styles.column, { width: "50%" }]}>
                  <Text style={styles.bulletPoint}>Fecha: {startDate} a {endDate}</Text>
                  <Text style={styles.bulletPoint}>
                    Instalación: {!!location ? location : "Todas"}
                  </Text>
                  <Text style={styles.bulletPoint}>
                    Sala: {!!room ? room : "Todas"}
                  </Text>
                </View>
                <Text style={[styles.title, { marginTop: "10px" }]}>
                  Reservas Centro de Experimentación
                </Text>
                
               <TableContainer bookings={bookings} />
              </View>
            </View>
          </Page>
        </>
      )}
    </Document>
  );
};
