/* eslint-disable no-mixed-operators */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";

//middleware
import Axios from "axios";
import { IBooking } from "../../interfaces/IBooking";
import { formatDate, formatTime } from "../../utils/auxiliar/dates";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPencilAlt,
  faTrash,
  faUserCheck,
  faUserClock,
  faUserTimes,
} from "@fortawesome/free-solid-svg-icons";
import { EquipmentModal } from "../Modals/EquipmentModal";
import { IEquipment } from "../../interfaces/IEquipment";
import { IUser } from "../../interfaces/IUser";
import { UsersModal } from "../Modals/UsersModal";
import { paths } from "../../utils/paths";
import { ROUTES } from "../../utils/routes";
import { useSelector } from "react-redux";
import { IStore } from "../../interfaces/IStore";
import { useHistory, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { Delete } from "../../utils/requests/dynamicReq";
import { SwalError } from "../../utils/constants";

interface IProps {
  bookings: IBooking[];
  metabookingStatus: string;
  user?: IUser;
}

export const BookingList = ({ bookings, metabookingStatus, user }: IProps) => {
  let { isAdmin } = useSelector(({ user }: IStore) => user as IUser);
  const history = useHistory();
  const { id: metaId }: any = useParams();

  const { BOOKINGS } = paths;

  const [equipments, setEquipments] = useState<IEquipment[]>([]);
  const [showEquipment, setShowEquipment] = useState(false);

  const [users, setUsers] = useState<IUser[]>([]);
  const [showUsers, setShowUsers] = useState(false);
  const [externalUser1, setExternalUser1] = useState("");
  const [externalUser2, setExternalUser2] = useState("");

  const isMountedRef = useRef(false);
  const source = Axios.CancelToken.source();

  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
      source.cancel();
    };
  }, []);

  const openEquipment = (equipments: IEquipment[]) => {
    setEquipments(equipments);
    setShowEquipment(true);
  };

  //prettier-ignore
  const openUsers = ({users, externalUser1, externalUser2}: IBooking) => {
    setUsers(users.filter(({id})=>id !== user?.id));
    setShowUsers(true);
    setExternalUser1(externalUser1)
    setExternalUser2(externalUser2)
  };

  const switchCheckInStatus = (status: string) => {
    switch (status) {
      case "On_time":
        return <FontAwesomeIcon color="#03d603" icon={faUserCheck} />;
      case "Delayed":
        return <FontAwesomeIcon color="#ecec06" icon={faUserClock} />;
      case "No_show":
        return <FontAwesomeIcon color="#e00303" icon={faUserTimes} />;
      default:
        return "";
    }
  };

  const deleteIt = (id: number) => {
    Swal.fire({
      icon: "question",
      text: "¿Seguro quieres eliminarl@? Este proceso es irreversible.",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "No",
    }).then(async ({ isConfirmed }) => {
      if (isConfirmed) {
        try {
          Delete(BOOKINGS, id, source).then(() => {
            Swal.fire({ icon: "success", text: "Eliminada correctamente" });
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          });
        } catch (error) {
          Swal.fire({ icon: "error", text: SwalError });
        }
      }
    });
  };

  return (
    <>
      <table className="table table-hover text-center">
        <thead className="bg-black text-light">
          <tr>
            <th scope="col">Fecha</th>
            <th scope="col">Horario</th>
            <th scope="col">Sala</th>
            <th scope="col">Descripción</th>
            <th scope="col">Instrumental</th>
            <th scope="col">Acompañante/s</th>
            {isAdmin && <th scope="col">Check-in</th>}
            <th scope="col">Editar</th>
            <th scope="col">Eliminar</th>
          </tr>
        </thead>
        <tbody className="bg-fffff">
          {bookings?.map((b) =>
            //prettier-ignore
            <tr key={b.id}>
                <td>{formatDate(b.startDate)}</td>
                <td>{`${formatTime(b.startDate)} - ${formatTime(b.endDate)}`}</td>
                <td>{b.room.name}</td>
                <td>{b.additionalInfo} </td>
                <td className="pointer c-lightblue" onClick={() => openEquipment(b.equipments)} >
                  <strong>Ver</strong>
                </td>
                <td className="pointer c-lightblue" onClick={() => openUsers(b)}  >
                  <strong>Ver</strong>
                </td>
                {isAdmin && <td>{switchCheckInStatus(b.checkInStatus!)}</td>}
                <td>
                  {(isAdmin || (!isAdmin && metabookingStatus === "pending")) && (
                    <div
                      onClick={() => history.push(`${ROUTES.Common.ManageBooking}/metabooking/${metaId}/booking/${b.id}`)}>
                      <FontAwesomeIcon className="pointer" color="#189bcc" icon={faPencilAlt}/>
                    </div>
                  )}
                </td>
                {(isAdmin || (!isAdmin && metabookingStatus === "pending")) && (
                  <td>
                    <div
                      onClick={() => deleteIt(b.id!)} >
                      <FontAwesomeIcon className="pointer" color="red" icon={faTrash} />
                    </div>
                  </td>
                )}
              </tr>
          )}
        </tbody>
      </table>
      <EquipmentModal
        show={showEquipment}
        onHide={() => setShowEquipment(false)}
        equipments={equipments}
      />
      <UsersModal
        show={showUsers}
        onHide={() => setShowUsers(false)}
        users={users}
        externaluser1={externalUser1}
        externaluser2={externalUser2}
      />
    </>
  );
};
