/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useRef, useState } from "react";

//middleware
import Axios from "axios";
import { Form, InputGroup } from "react-bootstrap";
import { faCalendar, faFileCsv, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CSVLink } from "react-csv";

import { More } from "../../../components/More/More";
import { getAll, getById } from "../../../utils/requests/dynamicReq";
import { paths } from "../../../utils/paths";
import { useSelector } from "react-redux";
import { IStore } from "../../../interfaces/IStore";
import { IUser } from "../../../interfaces/IUser";
import { MetaBookingList } from "../../../components/MetaBookingList/MetaBookingList";
import { IMetaBooking } from "../../../interfaces/IMetaBooking";
import { normalizer } from "../../../utils/auxiliar/normalizer";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../../utils/routes";
import { hideCompletedIrregular } from "../../../utils/auxiliar/metaBookingFilter";
import { bookingsToCsvList, csvHeaders, OrderCsvByBooking } from "../../../utils/auxiliar/csv";
import moment from "moment";
import { ICsvBooking } from "../../../interfaces/ICsvBooking";
import { locationKey } from "../../../enum/LocationType";

export const MetaBookings: FC = () => {
  const history = useHistory();
  const { METABOOKINGS, USERMETABOOKING, BOOKINGS } = paths;

  let { isAdmin, id } = useSelector(({ user }: IStore) => user as IUser);

  const isMountedRef = useRef(false);
  const source = Axios.CancelToken.source();

  const [metaBookings, setMetaBookings] = useState<IMetaBooking[]>([]);
  const [displayMetaBooking, setDisplayMetaBooking] = useState<IMetaBooking[]>([]);
  const [status, setStatus] = useState("");
  const [location, setLocation] = useState("");
  const [search, setSearch] = useState("");

  const [cvsData, setCSVData] = useState<ICsvBooking[]>([]);

  const [index, setIndex] = useState(6);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    console.log("running");

    setIsLoading(true);
    isMountedRef.current = true;
    loadData();
    return () => {
      isMountedRef.current = false;
      source.cancel();
    };
  }, []);

  const loadData = async () => {
    let userId = id.toString();
    try {
      const response = isAdmin ? await getAll(METABOOKINGS, source) : await getById(USERMETABOOKING, userId, source);

      const bookings = await getAll(BOOKINGS, source);

      if (isMountedRef.current) {
        setDisplayMetaBooking(response);
        isAdmin ? setMetaBookings(response) : setMetaBookings(hideCompletedIrregular(response));

        // EXPORT CSV
        let csv = bookingsToCsvList(bookings);
        setCSVData(OrderCsvByBooking(csv));

        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log("HTTP call cancelled");
    }
  };

  const cleanFilters = () => {
    setSearch("");
    setStatus("");
    setLocation("");
    setDisplayMetaBooking(metaBookings);
  };

  const filterStatus = (status: string) => {
    setStatus(status);
    multipleFilter(search, status, location);
  };

  const filterLocation = (location: string) => {
    setLocation(location);
    multipleFilter(search, status, location);
  };

  const searchBar = (search: string) => {
    setSearch(search);
    !search && setDisplayMetaBooking(metaBookings);
    search = search.toLowerCase();

    multipleFilter(search, status, location);
  };

  const multipleFilter = (value: string, pickedStatus: string, pickedLocation: string) => {
    setDisplayMetaBooking(
      metaBookings.filter(({ user, ip, status, location }: IMetaBooking) => {
        return (
          (normalizer(user?.name).includes(normalizer(value)) ||
            normalizer(user?.surname1).includes(normalizer(value)) ||
            (user?.surname2 && normalizer(user?.surname2).includes(normalizer(value))) ||
            normalizer(ip?.name).includes(normalizer(value)) ||
            normalizer(ip?.surname).includes(normalizer(value))) &&
          (!pickedStatus || status === pickedStatus) &&
          (pickedLocation === "" || location === pickedLocation)
        );
      })
    );
  };

  return (
    <>
      <div className="container-fluid pl-4">
        <div className="row mb-4">
          <div className="col-11">
            <h2>Reservas</h2>
          </div>
          {isAdmin && (
            <div className="col-1">
              <CSVLink
                data={cvsData}
                headers={csvHeaders}
                filename={`datos-Centro-Experimentacion${moment(new Date()).local().format("DD-MM-YYYY")}.csv`}
              >
                <button className="smallBtn ml-1 mr-1" style={{ width: "40px" }}>
                  <FontAwesomeIcon color="green" style={{ fontSize: "24px" }} icon={faFileCsv} />
                </button>
              </CSVLink>
            </div>
          )}
        </div>

        <div className="row">
          {isAdmin && (
            <Form.Group className="col-sm-4 col-12">
              <InputGroup className="shadow">
                <Form.Control
                  type="text"
                  placeholder="Buscar investigador/a o Ip..."
                  className="form-control"
                  value={search}
                  onChange={({ target: { value } }) => searchBar(value)}
                />
                <InputGroup.Append>
                  <InputGroup.Text className="inputGroup">
                    <FontAwesomeIcon color="#242424" icon={faSearch} />
                  </InputGroup.Text>
                </InputGroup.Append>
              </InputGroup>
            </Form.Group>
          )}
          <div className="col-sm-2 col-12">
            <select className="form-control" value={status} onChange={({ target: { value } }) => filterStatus(value)}>
              <option value={""}>Estado</option>
              <option value={"pending"}>Pendiente</option>
              <option value={"confirmed"}>Confirmada</option>
              <option value={"irregular"}>Incidencia</option>
              <option value={"completed"}>Completada</option>
              {isAdmin && <option value={"completed_irregular"}>Complet. con incidencia</option>}
            </select>
          </div>
          <div className="col-sm-2 col-12">
            <select
              className="form-control"
              value={location}
              onChange={({ target: { value } }) => filterLocation(value)}
            >
              <option value={""}>Instalación</option>
              <option value={locationKey.ceca}>{locationKey.ceca}</option>
              <option value={locationKey.medicina}>{locationKey.medicina}</option>
            </select>
          </div>
          <div className="col-sm-2 col-12">
            <button className="button-search mt-1 mb-4" onClick={cleanFilters}>
              Quitar filtros
            </button>
          </div>
          {isAdmin && (
            <div className="col-sm-2 col-12">
              <button className="button-search mt-1 mb-4" onClick={() => history.push(`${ROUTES.Common.Availability}`)}>
                <FontAwesomeIcon className=" mr-2 " icon={faCalendar} />
                Ver Calendario
              </button>
            </div>
          )}
        </div>

        {/* Se le pasa un array de objetos. Se carga dependiendo si el user es admin o no [TENER EN CUENTA SI ES TÉCNICX]*/}
        <div className="row mt-4">
          <div className="col-12 whiteDiv overFlowAuto">
            <MetaBookingList metaBookings={displayMetaBooking} index={index} />
            <More
              data={metaBookings}
              displayData={displayMetaBooking}
              isLoading={isLoading}
              setIndex={setIndex}
              index={index}
            />
          </div>
        </div>
      </div>
    </>
  );
};
