import Axios from "axios";
import { store } from "../..";
import { baseURL } from "../constants";

export const getAvailableEquipment = (path: string, body: any, source: any) => {
    //prettier-ignore
    let {user: { token } }: any = store.getState();
  
    return Axios.post(`${baseURL}${path}`, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      cancelToken: source.token,
    })
      .then(({ status, data: { result } }) => {
        if (status !== 201 && status !== 200) {
          return Promise.reject(status);
        } else {
          return Promise.resolve(result);
        }
      })
      .catch((err) => Promise.reject(err));
  };