export const ROUTES = {
  Login: "/",
  Admin: {
    Rooms: {
      all: "/salas",
      update: "/salas/:id",
      create: "/salas/crear",
    },
    Equipment: {
      all: "/instrumental",
      update: "/instrumental/:id",
      create: "/instrumental/crear",
    },
    Ips: {
      all: "/ips",
    },
    Users: {
      all: "/usuarios",
      update: "/usuarios/:id",
      create: "/usuarios/crear",
    },
    Inbox: {
      all: "/buzon",
    },
  },
  Common: {
    Availability: "/disponibilidad",
    Bookings: "/reservas",
    MetaBookings: "/metareservas",
    ManageBooking: "/gestionar-reserva",
    NewBooking: "/nueva-reserva/metabooking",
  },
  Mobile: {
    Checkin: "/check-in",
  },
  Technician: {
    DailyEquipments: "/equipos-diario",
    WeeklyBookings: "/reservas-semanales",
  },

  Not_Found: "*",
};
