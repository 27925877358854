import { TAction } from "../types";
import produce from "immer";
import { IBookingRedux } from "../../interfaces/IBookingRedux";

const initialState: IBookingRedux = {
  RxMetaBookingId: 0,
  RxId: 0,
  RxZoneId: 0,
  RxRoomId: 0,
  RxAdditionalInfo: "",
  RxBookingEquipment: [],
  RxBookingUsers: [],
  RxExternalUser1: "",
  RxExternalUser2: "",
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (
  state: IBookingRedux = initialState,
  action: TAction
): IBookingRedux =>
  produce(state, () => {
    switch (action.type) {
      case "SET_BOOKING":
        return action.payload;
      case "UNSET_BOOKING":
        return initialState;
      default:
        return state;
    }
  });
