/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

//css
import "./../Sidebar.css";

//middleware
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import {
  faUsers,
  faSignOutAlt,
  faCalendar,
  faBell,
  faPlusCircle,
  faDoorClosed,
  faVial,
  faHandshake,
  faUserCog,
} from "@fortawesome/free-solid-svg-icons";
import { ROUTES } from "../../../utils/routes";

interface IProps {
  logout: () => void;
  setShowPassword: any;
}

export const AdminSidebar = (props: IProps) => {
  //prettier-ignore
  const {logout, setShowPassword} = props;

  return (
    <Nav className="navbar navbar-expand-md navbar-dark  align-items-start flex-md-column flex-row bg-blue ">
      <a
        // href="javascript:void(0);"
        href="void(0);"
        className="navbar-toggler"
        data-toggle="collapse"
        data-target="#sidebarID"
      >
        <span className="navbar-toggler-icon"></span>
      </a>
      <div className="collapse navbar-collapse sidebar" id="sidebarID">
        <ul className="flex-column navbar-nav w-100 justify-content-between">
          <li>
            <Nav.Item>
              <NavLink className="nav-link pl-0" to={ROUTES.Admin.Inbox.all}>
                <FontAwesomeIcon className="iconClass mr-3" icon={faBell} />
                <span className="">Buzón de reservas</span>
              </NavLink>
            </Nav.Item>
          </li>
          <li>
            <Nav.Item>
              <NavLink className="nav-link pl-0" to={ROUTES.Common.MetaBookings}>
                <FontAwesomeIcon className="iconClass mr-3" icon={faCalendar} />
                <span className="">Reservas</span>
              </NavLink>
            </Nav.Item>
          </li>
          <li>
            <Nav.Item>
              <NavLink
                className="nav-link pl-0"
                to={`${ROUTES.Common.NewBooking}/0`}
              >
                <FontAwesomeIcon className="iconClass mr-3" icon={faPlusCircle} />
                <span className="">Nueva reserva</span>
              </NavLink>
            </Nav.Item>
          </li>
          <li>
            <Nav.Item>
              <NavLink className="nav-link pl-0" to={ROUTES.Admin.Rooms.all}>
                <FontAwesomeIcon className="iconClass mr-3" icon={faDoorClosed} />
                <span className="">Salas</span>
              </NavLink>
            </Nav.Item>
          </li>
          <li>
            <Nav.Item>
              <NavLink className="nav-link pl-0" to={ROUTES.Admin.Equipment.all}>
                <FontAwesomeIcon className="iconClass mr-3" icon={faVial} />
                <span className="">Instrumental</span>
              </NavLink>
            </Nav.Item>
          </li>
          <li>
            <Nav.Item>
              <NavLink className="nav-link pl-0" to={ROUTES.Admin.Users.all}>
                <FontAwesomeIcon className="iconClass mr-3" icon={faUsers} />
                <span className="">Usuarios</span>
              </NavLink>
            </Nav.Item>
          </li>
          <li>
            <Nav.Item>
              <NavLink className="nav-link pl-0" to={ROUTES.Admin.Ips.all}>
                <FontAwesomeIcon className="iconClass mr-3" icon={faHandshake} />
                <span className="">Inv. Principal</span>
              </NavLink>
            </Nav.Item>
          </li>
          <li className="nav-item responsiveLogout" onClick={logout}>
            <a className="nav-link pl-0" href="#">
              <FontAwesomeIcon className="iconClass mr-3" icon={faSignOutAlt} />
              <span className="">Cerrar sesión</span>
            </a>
          </li>
          <li
            className="nav-item responsiveLogout"
            onClick={() => setShowPassword(true)}
          >
            <a className="nav-link pl-0" href="#">
              <FontAwesomeIcon className="iconClass mr-3" icon={faUserCog} />
              <span className="">Contraseña</span>
            </a>
          </li>
        </ul>
      </div>
    </Nav>
  );
};
