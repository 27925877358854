/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useRef, useState } from "react";

//middleware
import Axios from "axios";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import Form from "react-bootstrap/esm/Form";
import InputGroup from "react-bootstrap/esm/InputGroup";
import { getAll } from "../../../../utils/requests/dynamicReq";
import { paths } from "../../../../utils/paths";
import { normalizer } from "../../../../utils/auxiliar/normalizer";
import { IUser } from "../../../../interfaces/IUser";

import { More } from "../../../../components/More/More";
import history from "../../../../utils/history";
import { ROUTES } from "../../../../utils/routes";
import { IIp } from "../../../../interfaces/IIp";
import { role } from "../../../../utils/roles";
import { switchRole } from "../../../../utils/constants";

export const Users: FC = () => {
  const { USERS, IPS } = paths;

  const [users, setUsers] = useState<IUser[]>([]);
  const [displayUsers, setDisplayUsers] = useState<IUser[]>([]);
  const [index, setIndex] = useState(10);
  const [isLoading, setIsLoading] = useState(false);

  //filters
  const [ips, setIps] = useState<IIp[]>([]);
  const [pickedIp, setPickedIp] = useState("");
  const [pickedRole, setPickedRole] = useState("");
  const [searchUser, setSearchUser] = useState("");

  const isMountedRef = useRef(false);
  const source = Axios.CancelToken.source();

  useEffect(() => {
    setIsLoading(true);
    isMountedRef.current = true;
    loadData();
    return () => {
      isMountedRef.current = false;
      source.cancel();
    };
  }, []);

  const loadData = async () => {
    try {
      const response = await getAll(USERS, source);

      const ips = await getAll(IPS, source);

      if (isMountedRef.current) {
        setDisplayUsers(response);
        setUsers(response);
        setIps(ips);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log("HTTP call cancelled");
    }
  };

  const searchBar = (value: string) => {
    setSearchUser(value);
    
    if (!value) return setDisplayUsers(users);

    value = value.toLowerCase();

    let filteredUsers = users.filter(({ name, surname1, surname2, phone }: IUser) => {
      return (
        (name && normalizer(name).includes(normalizer(value))) ||
        (surname1 && normalizer(surname1).includes(normalizer(value))) ||
        (surname2 && normalizer(surname2).includes(normalizer(value))) ||
        (phone && phone.includes(value))
      );
    });

    setDisplayUsers(filteredUsers);
  };

  const filterRole = (pickedRole: string) => {
    setSearchUser("");
    setPickedRole(pickedRole);
    multipleFilter(pickedRole, pickedIp);
  };

  const filterIp = (pickedIp: string) => {
    setSearchUser("");
    setPickedIp(pickedIp);
    multipleFilter(pickedRole, pickedIp);
  };

  const multipleFilter = (pickedRole: string, pickedIp: string) => {
    setDisplayUsers(
      users.filter(({ userRole, ip }: IUser) => {
        return (!pickedRole || userRole === pickedRole) && (pickedIp === "" || ip?.id === +pickedIp);
      })
    );
  };

  const cleanFilters = () => {
    setPickedIp("");
    setSearchUser("");
    setPickedRole("");
    setDisplayUsers(users);
  };
  return (
    <>
      <div className="container-fluid pl-4">
        <div className="row mb-4">
          <div className="col-12">
            <h2>Usuarios</h2>
          </div>
        </div>
        <div className="row">
          <Form.Group className="col-sm-3 col-12">
            <InputGroup className="shadow">
              <Form.Control
                type="text"
                placeholder="Buscar..."
                className="form-control"
                value={searchUser}
                onChange={({ target: { value } }) => searchBar(value)}
              />
              <InputGroup.Append>
                <InputGroup.Text className="inputGroup">
                  <FontAwesomeIcon color="#242424" icon={faSearch} />
                </InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
          </Form.Group>
          <div className="col-sm-3 col-12 ">
            <select
              className="  col form-control selectMultiple input "
              data-spy="scroll"
              value={pickedIp}
              onChange={({ target: { value } }) => filterIp(value)}
            >
              <option value={""}>IP</option>

              {ips.map(({ name, id, surname }) => (
                <option key={id} value={id}>
                  {surname}, {name}
                </option>
              ))}
            </select>
          </div>
          <div className="col-sm-2 col-12 ">
            <select
              className="form-control input"
              value={pickedRole}
              onChange={({ target: { value } }) => filterRole(value)}
            >
              <option value="">Rol</option>
              <option value={role.user}>Usuario</option>
              <option value={role.technician}>Técnico</option>
              <option value={role.admin}>Admin</option>
            </select>
          </div>

          <div className="col-sm-2 col-12 mt-1">
            <button className="button-search" onClick={cleanFilters}>
              Quitar filtros
            </button>
          </div>
          <div className="col-sm-2 col-12 mt-1 ">
            <button className="button-search" onClick={() => history.push(ROUTES.Admin.Users.create)}>
              <FontAwesomeIcon className="mr-2" icon={faPlus} />
              Nuevo Usuario
            </button>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-12 whiteDiv overFlowAuto">
            <table className="table table-hover text-center">
              <thead className="bg-black text-light">
                <tr>
                  <th scope="col">Nombre</th>
                  <th scope="col">Apellido/s</th>
                  <th scope="col">Teléfono</th>
                  <th scope="col">Rol</th>
                  <th scope="col">Inv. Principal</th>
                  <th scope="col">Editar</th>
                </tr>
              </thead>
              <tbody className="bg-fffff">
                {displayUsers?.slice(0, index)?.map(
                  //prettier-ignore
                  ({id, name, surname1, surname2, phone,ip, userRole}: IUser) => (
                      <tr key={id}>
                        <td>{name}</td>
                        <td>
                          {surname1} {surname2}
                        </td>
                        <td>{phone} </td>
                        <td>{switchRole(userRole!)} </td>
                        <td>{ip?.surname}, {ip?.name} </td>
                        <td className="pointer" 
                          onClick={() => history.push(`${ROUTES.Admin.Users.all}/${id}`)}>
                         <FontAwesomeIcon color="#242424" icon={faPencilAlt} />
                         </td>
                      </tr>
                    )
                )}
              </tbody>
            </table>
            <More data={users} displayData={displayUsers} isLoading={isLoading} setIndex={setIndex} index={index} />
          </div>
        </div>
      </div>
    </>
  );
};
