/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router";

//middleware
import Axios from "axios";
import { Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faSave, faRedo } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import { SwalError } from "../../../../utils/constants";
import moment from "moment";

import { role } from "../../../../utils/roles";
import { IIp } from "../../../../interfaces/IIp";
import {
  create,
  getAll,
  getById,
  update,
  resetPass
} from "../../../../utils/requests/dynamicReq";
import { paths } from "../../../../utils/paths";
import { IUser } from "../../../../interfaces/IUser";
import { validate } from "../Users/validations";

export const ManageUsers: FC = () => {
  const { USERS, IPS,RESETPASSWORD } = paths;
  const { id }: any = useParams();

  const isMountedRef = useRef(false);
  const source = Axios.CancelToken.source();
  const history = useHistory();

  const [name, setName] = useState("");
  const [surname1, setSurname1] = useState("");
  const [surname2, setSurname2] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [userRole, setUserRole] = useState("");
  const [ips, setIps] = useState([]);
  const [chosenIp, setChosenIp] = useState<number>();
  const [active, setActive] = useState(true);

  const [message, setMessage] = useState("");
  const [isCreate, setIsCreate] = useState(false);

  useEffect(() => {
    isMountedRef.current = true;
    const { pathname } = history.location;
    pathname.includes("crear") ? setIsCreate(true) : loadEditData();
    loadDefaultData();
    return () => {
      isMountedRef.current = false;
      source.cancel();
    };
  }, [isCreate]);

  const loadDefaultData = async () => {
    try {
      let draftIps = await getAll(IPS, source);
      if (isMountedRef.current) {
        setIps(draftIps);
      }
    } catch (error) {
      console.log("HTTP call cancelled");
    }
  };

  const loadEditData = async () => {
    try {
      let response = await getById(USERS, id, source);
      isMountedRef.current && build(response);
    } catch (error) {
      console.log("HTTP call cancelled");
    }
  };

  const build = (response: IUser) => {
    //prettier-ignore
    const { name, surname1, surname2, email, phone, userRole, ip, active } = response;
    setName(name);
    setSurname1(surname1);
    setSurname2(surname2);
    setEmail(email);
    setPhone(phone);
    setUserRole(userRole!);
    setActive(active!);
    setChosenIp(ip?.id);
  };

  //--------------------------------

  const handleSubmit = () => {
    const user = buildToSend();
    const notValid = validate(user);
    if (notValid) {
      setMessage(notValid);
      setTimeout(() => setMessage(""), 4000);
      return;
    }

    let question = isCreate ? "crear un" : "editar este";
    Swal.fire({
      icon: "question",
      text: `¿Quieres ${question} usuario?`,
      showCancelButton: true,
      confirmButtonText: "Si",
      cancelButtonText: "No",
    }).then(({ isConfirmed }) => {
      isConfirmed && handleConfirmation(user as IUser);
    });
  };

  const handleConfirmation = (user: IUser) => {
    let createOrUpdate = isCreate
      ? create(USERS, user, source)
      : update(USERS, id, user, source);
    createOrUpdate
      .then(async () => {
        const year = moment(new Date()).locale("es").format("YYYY");
        let createOrUpdateText = isCreate
          ? `creado correctamente. Con contraseña: Ceca${year}`
          : "editado correctamente.";
        Swal.fire({
          icon: "success",
          text: `Usuario ${createOrUpdateText} `,
        });

        isMountedRef.current && setIsCreate(false);
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          text: SwalError,
        });
      });
  };

        //HANDLE RESET PASSWORD

        const handleResetPassword = () => {
          Swal.fire({
            icon: "question",
            text: `¿Quieres resetear la contraseña de este usuario?`,
            showCancelButton: true,
            confirmButtonText: "Si",
            cancelButtonText: "No",
          }).then(({ isConfirmed }) => {
            isConfirmed &&
              resetPass(RESETPASSWORD, +id, source)
                .then(() => {
                  const year = moment(new Date()).locale("es").format("YYYY");
                  Swal.fire({
                    icon: "success",
                    text: `Contraseña reestablecida a Ceca${year}`,
                  });
                  isMountedRef.current && setIsCreate(false);
                })
                .catch(() => {
                  Swal.fire({
                    icon: "error",
                    text: SwalError,
                  });
                });
          });
        };

  const buildToSend = () => ({
    name,
    surname1,
    surname2,
    email,
    phone,
    ipId: +chosenIp!,
    userRole,
    active,
  });

  const header = isCreate ? "Nuevo Usuario" : "Editar Usuario";

  return (
    <>
      <div className="container-fluid pl-4">
        <div className="row mb-4">
          <div className="col-sm-6 col-12 d-flex justify-content-between">
            <h2>{header}</h2>
            <button className="baseButton" onClick={() => history.goBack()}>
              <FontAwesomeIcon icon={faArrowLeft} />
              Atrás
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6 col-12 whiteDiv p-4">
            <div className="form-row">
              <div className="col-sm-6 col-12">
                <h6>*Nombre</h6>
                <input
                  type="text"
                  className="form-control"
                  value={name}
                  onChange={({ target: { value } }) => setName(value)}
                />
              </div>

              <div className="col-sm-6 col-12">
                <h6>Activo</h6>
                <Form>
                  <Form.Check
                    type="switch"
                    id="custom-switch"
                    label={active ? "Sí" : "No"}
                    checked={active}
                    onChange={() => {
                      setActive(!active);
                    }}
                  />
                </Form>
              </div>
            </div>
            <div className="form-row mt-2">
              <div className="col-sm-6 col-12">
                <h6>*Primer apellido</h6>
                <input
                  type="text"
                  className="form-control"
                  value={surname1}
                  onChange={({ target: { value } }) => setSurname1(value)}
                />
              </div>
              <div className="col-sm-6 col-12">
                <h6>Segundo apellido</h6>
                <input
                  className="form-control"
                  value={surname2}
                  onChange={({ target: { value } }) => setSurname2(value)}
                />
              </div>
            </div>
            <div className="form-row mt-2">
              <div className="col-sm-6 col-12">
                <h6>*Correo electrónico</h6>
                <input
                  className="form-control"
                  value={email}
                  onChange={({ target: { value } }) => setEmail(value)}
                />
              </div>

              <div className="col-sm-6 col-12">
                <h6>*Rol</h6>
                <select
                  className="form-control input"
                  value={userRole}
                  onChange={({ target: { value } }) => setUserRole(value)}
                >
                  <option value="">Elegir rol</option>
                  <option value={role.user}>Usuario</option>
                  <option value={role.technician}>Técnico</option>
                  <option value={role.admin}>Admin</option>
                </select>
              </div>
            </div>
            <div className="form-row mt-2">
              <div className="col-sm-6 col-12">
                <h6>Teléfono</h6>
                <input
                  className="form-control"
                  value={phone}
                  onChange={({ target: { value } }) => setPhone(value)}
                />
              </div>
              <div className="col-sm-6 col-12">
                <h6>*IP</h6>
                <select
                  className="form-control input"
                  value={chosenIp}
                  onChange={({ target: { value } }) => {
                    setChosenIp(+value);
                  }}
                >
                  <option>Elige IP</option>
                  {ips.map(({ id, name, surname }: IIp) => (
                    <option key={id} value={id}>
                      {name} {surname}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="form-row mt-4">
              <div className="col-4 d-flex justify-content-center align-items-center">
                <div>
                  <button className="button-search" onClick={handleResetPassword}>
                    <FontAwesomeIcon className="mr-2" icon={faRedo} />
                    Reestablecer Contraseña
                  </button>
                </div>
              </div>
              <div className="col-4 d-flex justify-content-center align-items-center">
                <div>
                  <button className="button-search" onClick={handleSubmit}>
                    <FontAwesomeIcon className="mr-2" icon={faSave} />
                    Guardar
                  </button>
                </div>
              </div>
            </div>
            <div className="form-row mt-2 text-danger">
              <div className="col-12 d-flex justify-content-center">
                <p>{message} </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
