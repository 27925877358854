import { IUser } from "../../interfaces/IUser";

export const userIds = (users: IUser[], mainUserId: number) => {
  let filteredUsers: number[] = [mainUserId];
  // eslint-disable-next-line array-callback-return
  users.map(({ id }) => {
    filteredUsers.push(id);
  });
  return filteredUsers;
};
