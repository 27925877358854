/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";

//middleware
import Axios from "axios";
import { Calendar } from "../../../components/Calendar/Calendar";
import { BookingForm } from "../../../components/BookingForm/BookingForm";
import { paths } from "../../../utils/paths";

import { getByFilter } from "../../../utils/requests/dynamicReq";
import { buildFullBookings } from "../../../utils/auxiliar/bookingsFilters";
import { ICalendarBooking } from "../../../interfaces/ICalendarBooking";
import moment from "moment";
import { useSelector } from "react-redux";
import { IStore } from "../../../interfaces/IStore";
import { IBookingRedux } from "../../../interfaces/IBookingRedux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router";

export const ManageBooking: any = () => {
  const { BOOKINGS } = paths;
  const history = useHistory();

  let { RxZoneId } = useSelector(({ booking }: IStore) => booking as IBookingRedux);

  const isMountedRef = useRef(false);
  const source = Axios.CancelToken.source();

  const [isCreate, setIsCreate] = useState(false);

  const [date, setDate] = useState<Date>(moment().toDate());
  const [bookings, setBookings] = useState<ICalendarBooking[]>([]);
  const [roomId, setRoomId] = useState<number | undefined>(RxZoneId);

  useEffect(() => {
    isMountedRef.current = true;
    const { pathname } = history.location;

    pathname.includes("nueva") && setIsCreate(true);

    return () => {
      isMountedRef.current = false;
      setBookings([]);
      setRoomId(undefined);
      source.cancel();
    };
  }, []);

  const loadRoomAvailability = async (roomId?: number) => {
    if (!roomId) {
      setBookings([]);
      return;
    }
    try {
      setRoomId(roomId);
      const availability = await getByFilter(BOOKINGS, "roomId", roomId!, source);

      if (isMountedRef.current) {
        let fullBookings = buildFullBookings(availability);
        setBookings(fullBookings);
        return fullBookings;
      }
    } catch (error) {
      console.log("HTTP call cancelled");
    }
  };

  return (
    <>
      <div className="container-fluid pl-4">
        <div className="row">
          <div className="col-12 d-flex justify-content-between">
            <h2>{isCreate ? "Nueva reserva" : "Editar reserva"}</h2>
            {!!!isCreate && (
              <button className="baseButton" onClick={() => history.goBack()}>
                <FontAwesomeIcon icon={faArrowLeft} />
                Atrás
              </button>
            )}
          </div>
        </div>

        <div className="row">
          {/* mt-4 needs to go at this level for responsive reasons */}
          <div className="col-sm-7 col-12 whiteDiv mt-4">
            <div className="row ">
              <div className="smallSquare mt-1 ml-3 mr-1 bg-pink" />
              <span>A reservar</span>
              <div className="smallSquare mt-1 ml-3 mr-1 bg-lightblue" />
              <span>Pendiente</span>
              <div className="smallSquare mt-1 ml-3 mr-1 bg-blue" />
              <span>Confirmado</span>
              <div className="smallSquare mt-1 ml-3 mr-1 bg-lightgrey" />
              <span>Limpieza</span>
            </div>
            <Calendar
              bookings={bookings}
              height={1000}
              date={date}
              route={"newBooking"}
            />
          </div>
          <div className="col whiteDiv ml-3 mt-4 pr-4 pl-4">
            <BookingForm
              setRoomId={setRoomId}
              loadRoomAvailability={loadRoomAvailability}
              roomId={roomId}
              setDate={setDate}
              setBookings={setBookings}
              bookings={bookings}
            />
          </div>
        </div>
      </div>
    </>
  );
};
