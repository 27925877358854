/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useRef, useState } from "react";

//middleware
import Axios from "axios";
import { Form, InputGroup } from "react-bootstrap";
import { faPlus, faSave, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";
import { SwalError } from "../../../../utils/constants";

//utils
import { IIp } from "../../../../interfaces/IIp";
import { paths } from "../../../../utils/paths";
import {
  create,
  getAll,
  getById,
  update,
} from "../../../../utils/requests/dynamicReq";
import { More } from "../../../../components/More/More";
import { normalizer } from "../../../../utils/auxiliar/normalizer";
import { validate } from "./validations";
import { CreateIp } from "./CreateIp";

export const ManageIps: FC = () => {
  const [isLoading, setIsLoading] = useState(false);

  const { IPS } = paths;

  const [allIps, setAllIps] = useState<IIp[]>([]);
  const [displayIps, setDisplayIps] = useState<IIp[]>([]);

  const [active, setActive] = useState(true);
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");

  const [status, setStatus] = useState("");
  const [search, setSearch] = useState("");
  const [pickedId, setPickedId] = useState<number | string>();

  const [isCreate, setIsCreate] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [index, setIndex] = useState(10);
  const [message, setMessage] = useState("");
  const isMountedRef = useRef(false);
  const source = Axios.CancelToken.source();

  useEffect(() => {
    setIsLoading(true);
    loadData();
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
      source.cancel();
    };
  }, []);

  const loadData = async () => {
    try {
      const response = await getAll(IPS, source);
      if (isMountedRef.current) {
        setAllIps(response);
        setDisplayIps(response);

        colorCondition(response[0]?.id);
        handlePickedId(response[0]?.id);

        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log("HTTP call cancelled");
    }
  };

  const loadDataToEdit = async (id: any) => {
    try {
      let response = await getById(IPS, id, source);

      isMountedRef.current && build(response);
    } catch (error) {
      console.log("HTTP call cancelled");
    }
  };
  const build = (response: any) => {
    const { name, surname, active } = response;
    setName(name);

    setSurname(surname);
    setActive(active);
  };

  //--------------------------------

  const cleanFilters = () => {
    setStatus("");
    setSearch("");
    setDisplayIps(allIps);
  };

  const filterStatus = (status: string) => {
    setStatus(status);
    multipleFilter(search, status);
  };

  const searchBar = (search: string) => {
    setSearch(search);
    !search && setDisplayIps(allIps);
    search = search.toLowerCase();

    multipleFilter(search, status);
  };

  const multipleFilter = (search: string, status: string | boolean) => {
    setDisplayIps(
      allIps.filter(({ name, surname, active }: IIp) => {
        return (
          (normalizer(name).includes(normalizer(search)) ||
            normalizer(surname).includes(normalizer(search))) &&
          // eslint-disable-next-line eqeqeq
          (status === "" || active == status)
        );
      })
    );
  };

  const colorCondition = (id: number) => {
    if (id === pickedId) return true;
  };

  const handlePickedId = (id: any) => {
    setPickedId(id);
    setIsCreate(false);
    loadDataToEdit(id);
  };

  //--------------------------------

  const addNewIp = () => {
    setIsCreate(true);
    setName("");
    setSurname("");
    setActive(true);
    setShowModal(true);
  };

  const dismissNewIp = () => {
    setIsCreate(false);
    colorCondition(displayIps[0]?.id!);
    handlePickedId(displayIps[0]?.id!);
    setShowModal(false);
  }

  //--------------------------------

  const handleSubmit = () => {
    const ip = buildToSend();
    const notValid = validate(ip);
    if (notValid) {
      setMessage(notValid);
      setTimeout(() => setMessage(""), 4000);
      return;
    }

    let question = isCreate ? "crear un" : "editar este";
    Swal.fire({
      icon: "question",
      text: `¿Quieres ${question} ip?`,
      showCancelButton: true,
      confirmButtonText: "Si",
      cancelButtonText: "No",
    }).then(({ isConfirmed }) => {
      isConfirmed && handleConfirmation(ip as IIp);
    });
  };

  const handleConfirmation = (ip: IIp) => {
    let createOrUpdate = isCreate
      ? create(IPS, ip, source)
      : update(IPS, +pickedId!, ip, source);
    createOrUpdate
      .then(async () => {
        let createOrUpdateText = isCreate ? "creado" : "editado";
        Swal.fire({
          icon: "success",
          text: `Usuario ${createOrUpdateText} correctamente.`,
        });

        if (isMountedRef.current) {
          setIsCreate(false);
          loadData();
        }
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          text: SwalError,
        });
      });
  };

  const buildToSend = () => ({
    name,
    surname,
    active,
  });

  return (
    <>
      <div className="container-fluid pl-4">
        <div className="row mb-4">
          <div className="col-12">
            <h2>Investigador Principal</h2>
          </div>
        </div>
        <div className="row">
          <Form.Group className="col-sm-4 col-12">
            <InputGroup className="shadow">
              <Form.Control
                type="text"
                placeholder="Buscar..."
                className="form-control"
                value={search}
                onChange={({ target: { value } }) => searchBar(value)}
              />
              <InputGroup.Append>
                <InputGroup.Text className="inputGroup">
                  <FontAwesomeIcon color="#242424" icon={faSearch} />
                </InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
          </Form.Group>
          <div className="col-sm-3 col-12">
            <select
              className="form-control"
              value={status}
              onChange={({ target: { value } }) => filterStatus(value)}
            >
              <option value={""}>Activo</option>
              <option value={1}>Sí</option>
              <option value={0}>No</option>
            </select>
          </div>
          <div className="col-sm-3 col-12">
            <button className="button-search mt-1 mb-4" onClick={cleanFilters}>
              Quitar filtros
            </button>
          </div>
          <div className="col-sm-2 col-12">
            <button className="button-search mt-1 mb-4 " onClick={addNewIp}>
              <FontAwesomeIcon className=" mr-2 " icon={faPlus} />
              Nuevo IP
            </button>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-5 mt-2 col-12 whiteDiv overFlowAuto p-4">
            <table className="table table-hover text-center">
              <thead className="bg-black text-light">
                <tr>
                  <th scope="col">Nombre IP</th>
                  <th scope="col">Activo</th>
                </tr>
              </thead>
              <tbody className="bg-fffff">
                {displayIps
                  ?.slice(0, index)
                  ?.map(({ id, name, surname, active }: IIp) => (
                    <tr
                      key={id}
                      className={
                        colorCondition(id!) ? "bg-lightblue pointer" : "pointer"
                      }
                      onClick={() => handlePickedId(id)}
                    >
                      <td>
                        {`${name}
                          ${surname}`}
                      </td>
                      <td>{active ? "Sí" : "No"}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <More
              data={displayIps}
              displayData={displayIps}
              isLoading={isLoading}
              setIndex={setIndex}
              index={index}
            />
          </div>

          <div className="col-1" />

          <div className="col-sm-5 mt-2 col-12 ">
            <div className=" whiteDiv p-4">
              <div className="form-row">
                <div className="col-sm-9 col-12">
                  <h6>*Nombre</h6>
                  <input
                    type="text"
                    className="form-control"
                    value={name}
                    onChange={({ target: { value } }) => {
                      setName(value);
                    }}
                  />
                </div>
                <div className="col-sm-3 pl-4 col-12">
                  <h6>Habilitada</h6>
                  <Form>
                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      label={active ? "Sí" : "No"}
                      checked={active}
                      onChange={() => {
                        setActive(!active);
                      }}
                    />
                  </Form>
                </div>
              </div>
              <div className="form-row mt-2">
                <div className="col-sm-9 col-12">
                  <h6>*Apellidos</h6>
                  <input
                    type="text"
                    className="form-control"
                    value={surname}
                    onChange={({ target: { value } }) => {
                      setSurname(value);
                    }}
                  />
                </div>
              </div>

              <div className="form-row mt-4">
                <div className="col-12 d-flex justify-content-center">
                  <div>
                    <button className="button-search" onClick={handleSubmit}>
                      <FontAwesomeIcon className="mr-2" icon={faSave} />
                      Guardar
                    </button>
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-12 text-center text-danger">
                  <span>{message}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <CreateIp
        show={showModal}
        onHide={dismissNewIp}
        name={name}
        setName={setName}
        surname={surname}
        setSurname={setSurname}
        active={active}
        setActive={setActive}
        handleSubmit={handleSubmit}
      />
    </>
  );
};
