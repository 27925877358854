/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useRef, useState } from "react";

//middleware
import Axios from "axios";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import Form from "react-bootstrap/esm/Form";
import InputGroup from "react-bootstrap/esm/InputGroup";
import { getAll } from "../../../../utils/requests/dynamicReq";
import { paths } from "../../../../utils/paths";
import { normalizer } from "../../../../utils/auxiliar/normalizer";

import { More } from "../../../../components/More/More";
import history from "../../../../utils/history";
import { ROUTES } from "../../../../utils/routes";
import { IEquipment } from "../../../../interfaces/IEquipment";
import { stringShortener } from "../../../../utils/auxiliar/stringShortener";

export const Equipment: FC = () => {
  const { EQUIPMENT } = paths;

  const [equipment, setEquipment] = useState<IEquipment[]>([]);
  const [displayEquipment, setDisplayEquipment] = useState<IEquipment[]>([]);
  const [index, setIndex] = useState(10);
  const [isLoading, setIsLoading] = useState(false);

  const isMountedRef = useRef(false);
  const source = Axios.CancelToken.source();

  useEffect(() => {
    setIsLoading(true);
    isMountedRef.current = true;
    loadData();
    return () => {
      isMountedRef.current = false;
      source.cancel();
    };
  }, []);

  const loadData = async () => {
    try {
      const response = await getAll(EQUIPMENT, source);
      if (isMountedRef.current) {
        setDisplayEquipment(response);
        setEquipment(response);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log("HTTP call cancelled");
    }
  };

  const searchBar = (value: any) => {
    !value && setDisplayEquipment(equipment);
    value = normalizer(value.toLowerCase());

    setDisplayEquipment(
      equipment.filter(
        ({ name, location, description, equipmentCategory }: IEquipment) => {
          return (
            normalizer(name).includes(value) ||
            normalizer(location).includes(value) ||
            normalizer(description).includes(value) ||
            normalizer(equipmentCategory?.name!).includes(value)
          );
        }
      )
    );
  };

  return (
    <>
      <div className="container-fluid pl-4">
        <div className="row mb-4">
          <div className="col-12">
            <h2>Instrumental</h2>
          </div>
        </div>
        <div className="row">
          <Form.Group className="col-sm-6 col-12">
            <InputGroup className="shadow">
              <Form.Control
                type="text"
                placeholder="Buscar..."
                className="form-control"
                onChange={({ target: { value } }) => searchBar(value)}
              />
              <InputGroup.Append>
                <InputGroup.Text className="inputGroup">
                  <FontAwesomeIcon color="#242424" icon={faSearch} />
                </InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
          </Form.Group>
          <div className="col-sm-6 col-12 mt-2">
            <div>
              <button
                className="button-search"
                onClick={() => history.push(ROUTES.Admin.Equipment.create)}
              >
                <FontAwesomeIcon className="mr-2" icon={faPlus} />
                Nuevo Instrumental
              </button>
            </div>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-12 whiteDiv overFlowAuto">
            <table className="table table-hover text-center">
              <thead className="bg-black text-light">
                <tr>
                  <th scope="col">Nombre</th>
                  <th scope="col">Categoría/s</th>
                  <th scope="col">Ubicación</th>
                  <th scope="col">Descripción</th>
                  <th scope="col">Habilitado</th>
                  <th scope="col">Editar</th>
                </tr>
              </thead>
              <tbody className="bg-fffff">
                {displayEquipment?.slice(0, index)?.map(
                  //prettier-ignore
                  ({id, name, equipmentCategory, location, description, active, }: IEquipment) => (
                      <tr key={id}>
                        <td>{name}</td>
                        <td>{equipmentCategory?.name!} </td>
                        <td>{location}</td>
                        <td>{stringShortener(description, 7)} </td>
                        <td>{active? "Sí": "No"} </td>
                        <td className="pointer" 
                          onClick={() => history.push(`${ROUTES.Admin.Equipment.all}/${id}`)}>
                         <FontAwesomeIcon color="#242424" icon={faPencilAlt} />
                         </td>
                      </tr>
                    )
                )}
              </tbody>
            </table>
            {/* prettier-ignore */}
            <More data={equipment} displayData={displayEquipment} isLoading={isLoading} setIndex={setIndex} index={index} />
          </div>
        </div>
      </div>
    </>
  );
};
