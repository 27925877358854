/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import  {  useState } from "react";

//css
import "./Sidebar.css";

//utils
import { LogoutAction } from "../../redux/actions";
import logo from "../../assets/images/MARCA_UNIVERSIDAD_BLANCO_TRANS_1.png";

//middleware
import Axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import {  useHistory } from "react-router-dom";
import {
  faSignOutAlt,
  faUserCog,
} from "@fortawesome/free-solid-svg-icons";
import { IStore } from "../../interfaces/IStore";
import { IUser } from "../../interfaces/IUser";
import { PasswordModal } from "../Modals/PasswordModal";
import Swal from "sweetalert2";
import { create } from "../../utils/requests/dynamicReq";
import { SwalError } from "../../utils/constants";
import { paths } from "../../utils/paths";
import { AdminSidebar } from "./SidebarComponents/AdminSidebar";
import { role } from "../../utils/roles";
import { UserSidebar } from "./SidebarComponents/UserSidebar";
import { TechnicianSidebar } from "./SidebarComponents/TechnicianSidebar";

interface INewPassword {
  oldPassword: string;
  newPassword: string;
}

export const Sidebar = () => {
  //prettier-ignore
  let {  userRole } = useSelector(({ user }: IStore) => user as IUser);

  const dispatch = useDispatch();
  const history = useHistory();
  const { NEWPASSWORD } = paths;

  const [loading, setLoading] = useState(false);

  //change Password
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [repeatNewPass, setRepeatNewPass] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const source = Axios.CancelToken.source();

    //----------------
  //HANDLE CAHNGE PASSWORD

  const handleSubmitNewPassword = () => {
   
    const newPasswordForm: INewPassword = {
      oldPassword,
      newPassword,
    };
    Swal.fire({
      icon: "question",
      text: `¿Quieres cambiar tu contraseña?`,
      showCancelButton: true,
      confirmButtonText: "Si",
      cancelButtonText: "No",
    }).then(({ isConfirmed }) => {
      isConfirmed && handleNewPasswordConfirmation(newPasswordForm);
    });
  };

  const handleNewPasswordConfirmation = (newPasswordForm: INewPassword) => {
    create(NEWPASSWORD, newPasswordForm, source)
      .then(() => {
        Swal.fire({
          icon: "success",
          text: `Contraseña cambiada correctamente.`,
        });
        setNewPassword("");
        setOldPassword("");
        setRepeatNewPass("");
        setShowPassword(false);
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          text: SwalError,
        });
      });
  };

//----------------
  const logout = () => {
    setLoading(true);
    dispatch(LogoutAction());
    localStorage.removeItem("token");
    history.push("/");
  };

  const admin = userRole === role.admin;
  const user = userRole === role.user;
  const technician = userRole === role.technician;

  return (
    <>
      <aside className="col-12 col-md-2 p-0  flex-shrink-1 bg-blue  centerPage">
        <div className="sidebar-heading mt-4 ml-2">
          <img className="loginLogo ml-4" src={logo} alt="" />
        </div>
        <div className="sidebar-heading mt-3 whiteHeader ">
          <h6 className=" sidebarText">Panel de reservas</h6>
        </div>
        {admin && <AdminSidebar logout={logout} setShowPassword={setShowPassword}/>}
        {user && <UserSidebar logout={logout} setShowPassword={setShowPassword}/>}
        {technician && <TechnicianSidebar logout={logout} setShowPassword={setShowPassword}/>}
        
        <div className="flex-row navbar-nav w-100 changePassword" onClick={()=>setShowPassword(true)} >
          <FontAwesomeIcon className="iconClass mr-4 mt-3" icon={faUserCog} />
            <span className="d-none d-md-inline mr-2" style={{ marginTop: "5%" }}>
            Contraseña
            </span>
        </div>

        <div className="flex-row navbar-nav w-100 logoutButton" onClick={logout}>
          <FontAwesomeIcon className="iconClass mr-4 mt-3" icon={faSignOutAlt} />
          {!loading ? (
            <span className="d-none d-md-inline mr-2" style={{ marginTop: "5%" }}>
              Cerrar sesión
            </span>
          ) : (
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            />
          )}
        </div>
      </aside>

      <PasswordModal
          show={showPassword}
          onHide={() => setShowPassword(false)}
          oldPassword={oldPassword}
          setOldPassword={setOldPassword}
          newPassword={newPassword}
          setNewPassword={setNewPassword}
          repeatNewPass={repeatNewPass}
          setRepeatNewPass ={setRepeatNewPass}
          handleSubmitNewPassword={handleSubmitNewPassword}
        />
    </>
  );
};
