import moment from "moment";
import { ICalendarBooking } from "../../interfaces/ICalendarBooking";
import { formatDateTime } from "../../utils/auxiliar/dates";

export interface IMetabookingSHORT {
  location: string;
  ipId: number | undefined;
  userId: number | undefined;
  animalId: number | undefined;
  status: "pending" | "confirmed";
}

//prettier-ignore
export const validateMetaBooking = (metabooking: IMetabookingSHORT, roomId?: number) => {
  let aux = "";
  if (!metabooking.location) aux = "Por favor elija una instalación";
  if (!metabooking.ipId) aux = "Por favor asocie un IP a su cuenta para reservar";
  if (!metabooking.userId) aux = "Por favor elija un responsable del experimento";
  if (!metabooking.animalId) aux = "Por favor elija un animal";
  if (!roomId ) aux = "Por favor elija una sala a reservar";
    return aux;
};

export const validateBooking = (startDate?: Date, endDate?: Date) => {
  let aux = "";
  if (!startDate || !endDate) aux = "Por favor elija fechas apropiadas";
  if (endDate && startDate && +endDate === +startDate)
    aux = "Por favor elija un rango de tiempo";

  return aux;
};

//prettier-ignore
export const validateOverlap = ( bookings: ICalendarBooking[], startDate: any, endDate: any, bookingId: number) => {

  let aux = "";

  startDate = formatDateTime(startDate);
  endDate = moment(endDate).add(30, "m");
  endDate = formatDateTime(endDate);
  // eslint-disable-next-line array-callback-return
  bookings.find((b) => {
    
    //Avoid comparing it to itself or to the cleaning for itself
    // eslint-disable-next-line eqeqeq
    if (bookingId != b.id && bookingId !=b.bookingIdCleaning && startDate! < b.endDate && b.startDate < endDate!) {
      if (b.status !== "toBook") aux = "Las fechas a reservar coinciden con otra reserva.";
    }
  });
  return aux;
};
