import { AppointmentTooltip } from "@devexpress/dx-react-scheduler-material-ui";
import {
  faEnvelope,
  faPhoneSquareAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid } from "@material-ui/core";

export const AppointmentTooltipComponent = ({ style, ...restProps }: any) => {
  return (
    <AppointmentTooltip.Content {...restProps}>
      <Grid container alignItems="center">
        {restProps?.appointmentData?.user && (
          <Grid item xs={2}>
            <FontAwesomeIcon
              color="#0099cc"
              className=" ml-4 mb-2 mt-2"
              style={{ fontSize: "20px" }}
              icon={faPhoneSquareAlt}
            />
          </Grid>
        )}
        <Grid item xs={10}>
          <span className=" mb-2">
            {restProps?.appointmentData?.user?.phone}
          </span>
        </Grid>
        {restProps?.appointmentData?.user && (
          <Grid item xs={2}>
            <FontAwesomeIcon
              color="#0099cc"
              className=" ml-4"
              style={{ fontSize: "20px" }}
              icon={faEnvelope}
            />
          </Grid>
        )}
        <Grid item xs={10}>
          <span>{restProps.appointmentData?.user?.email}</span>
        </Grid>
      </Grid>
    </AppointmentTooltip.Content>
  );
};
