/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useRef, useState } from "react";

//middleware
import Axios from "axios";
import { useHistory, useParams } from "react-router";
import {
  create,
  getAll,
  getById,
  update,
} from "../../../../utils/requests/dynamicReq";
import { paths } from "../../../../utils/paths";
import { validate } from "../Equipment/validations";
import Swal from "sweetalert2";
import { SwalError } from "../../../../utils/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faSave } from "@fortawesome/free-solid-svg-icons";
import { IEquipment } from "../../../../interfaces/IEquipment";
import { Form } from "react-bootstrap";
import { locationKey } from "../../../../enum/LocationType";

export const ManageEquipment: FC = () => {
  const { EQUIPMENT, EQUIPMENTCATEGORY } = paths;
  const { id }: any = useParams();

  const isMountedRef = useRef(false);
  const source = Axios.CancelToken.source();
  const history = useHistory();

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [location, setLocation] = useState("Ceca");
  const [active, setActive] = useState(false);
  const [transportable, setTransportable] = useState(false);
  const [categories, setCategories] = useState([]);
  const [chosenCategoryId, setChosenCategoryId] = useState<number>();

  const [message, setMessage] = useState("");
  const [isCreate, setIsCreate] = useState(false);

  useEffect(() => {
    isMountedRef.current = true;
    const { pathname } = history.location;
    pathname.includes("crear") ? setIsCreate(true) : loadEditData();
    loadDefaultData();
    return () => {
      isMountedRef.current = false;
      source.cancel();
    };
  }, [isCreate]);

  const loadDefaultData = async () => {
    try {
      let draftIps = await getAll(EQUIPMENTCATEGORY, source);
      if (isMountedRef.current) {
        setCategories(draftIps);
      }
    } catch (error) {
      console.log("HTTP call cancelled");
    }
  };

  const loadEditData = async () => {
    try {
      let response = await getById(EQUIPMENT, id, source);
      isMountedRef.current && build(response);
    } catch (error) {
      console.log("HTTP call cancelled");
    }
  };

  const build = (response: any) => {
    const {
      name,
      description,
      location,
      transportable,
      active,
      equipmentCategory,
    } = response;
    setName(name);
    setDescription(description);
    setLocation(location);
    setTransportable(transportable);
    setActive(active);
    setChosenCategoryId(+equipmentCategory?.id!);
  };

  //--------------------------------

  const handleSubmit = () => {
    const equipment: IEquipment = buildToSend();

    const notValid = validate(equipment);
    if (notValid) {
      setMessage(notValid);
      setTimeout(() => setMessage(""), 4000);
      return;
    }
    let question = isCreate ? "crear un" : "editar este";
    Swal.fire({
      icon: "question",
      text: `¿Quieres ${question} instrumental?`,
      showCancelButton: true,
      confirmButtonText: "Si",
      cancelButtonText: "No",
    }).then(({ isConfirmed }) => {
      isConfirmed && handleConfirmation(equipment);
    });
  };

  const handleConfirmation = (equipment: IEquipment) => {
    let createOrUpdate = isCreate
      ? create(EQUIPMENT, equipment, source)
      : update(EQUIPMENT, id, equipment, source);
    createOrUpdate
      .then(async () => {
        let createOrUpdateText = isCreate ? "creado" : "editado";
        Swal.fire({
          icon: "success",
          text: `Instrumental ${createOrUpdateText} correctamente.`,
        });

        isMountedRef.current && setIsCreate(false);
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          text: SwalError,
        });
      });
  };

  const buildToSend = (): IEquipment => ({
    name,
    active,
    location,
    transportable,
    equipmentCategoryId: +chosenCategoryId!,
    description,
  });

  const header = isCreate ? "Nuevo Instrumental" : "Editar Instrumental";

  // const limit = (element: any) => {
  //   let max_chars = 5;

  //   let e = element.target.value
  //   var withOutSpace = e.replace(/\s+/g, '').length;

  //   console.log(withOutSpace)
  // };

  return (
    <>
      <div className="container-fluid pl-4">
        <div className="row mb-4">
        <div className="col-sm-6 col-12 d-flex justify-content-between">
            <h2>{header}</h2>
            <button className="baseButton" onClick={() => history.goBack()}>
              <FontAwesomeIcon icon={faArrowLeft} />
              Atrás
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6 col-12 whiteDiv p-4">
            <div className="form-row">
              <div className="col-sm-6 col-12">
                <h6>*Nombre</h6>
                <input
                  type="text"
                  className="form-control"
                  value={name}
                  onChange={({ target: { value } }) => {
                    setName(value);
                  }}
                />
              </div>
              <div className="col-sm-6 col-12">
                <h6>Habilitada</h6>
                <Form>
                  <Form.Check
                    type="switch"
                    id="active"
                    label={active ? "Sí" : "No"}
                    checked={active}
                    onChange={() => {
                      setActive(!active);
                    }}
                  />
                </Form>
              </div>
            </div>
            <div className="form-row mt-2">
              <div className="col-sm-6 col-12">
                <h6>*Ubicación</h6>
                <select
                  className="form-control input"
                  value={location}
                  onChange={({ target: { value } }) => {
                    setLocation(value);
                  }}
                >
                  <option value={locationKey.ceca}>{locationKey.ceca}</option>
                  <option value={locationKey.medicina}>{locationKey.medicina}</option>
                </select>
              </div>
              <div className="col-sm-6 col-12">
                <h6>Transportable</h6>
                <Form>
                  <Form.Check
                    type="switch"
                    id="transportable"
                    label={transportable ? "Sí" : "No"}
                    checked={transportable}
                    onChange={() => {
                      setTransportable(!transportable);
                    }}
                  />
                </Form>
              </div>
            </div>

            <div className="form-row mt-2">
              <div className="col-12">
                <h6>*Categoría</h6>
                <select
                  className="form-control input"
                  value={chosenCategoryId}
                  onChange={({ target: { value } }) => {
                    setChosenCategoryId(+value);
                  }}
                >
                  <option>Selecciona categoría</option>
                  {categories.map(({ id, name }: any) => (
                    <option key={id} value={id}>
                      {name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="form-row mt-2">
              <div className="col-12">
                <h6>*Descripción</h6>

                <textarea
                  className="form-control"
                  // onKeyDown={limit}
                  // onKeyUp={limit}
                  value={description}
                  ng-trim={false}
                  maxLength={100}
                  //TODO - Contar que sea un máximo de 50 caracteres (tal vez a la hora de enviar el formulario? En validations?)
                  onChange={({ target: { value } }) => {
                    setDescription(value);
                  }}
                />
              </div>
              <small>Máx. 100 caracteres</small>
            </div>

            <div className="form-row mt-4">
              <div className="col-12 d-flex justify-content-center">
                <div>
                  <button className="button-search" onClick={handleSubmit}>
                    <FontAwesomeIcon className="mr-2" icon={faSave} />
                    Guardar
                  </button>
                </div>
              </div>
            </div>
            <div className="form-row mt-2 text-danger">
              <div className="col-12 d-flex justify-content-center">
                <p>{message} </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
