export const switchStatus = (status: string) => {
  switch (status) {
    case "pending":
      return "Pendiente";
    case "confirmed":
      return "Confirmada";
    case "irregular":
      return "Incidencia";
    case "completed":
      return "Completada";
    case "completed_irregular":
      return "Complet/incid";
  }
};

export const switchStatusColor = (status: string) => {
  switch (status) {
    case "pending":
      return "bg-lightblue c-white";
    case "confirmed":
      return "bg-blue c-white";
    case "irregular":
      return "bg-black c-white";
    case "completed":
      return "bg-darkgrey c-white";
    case "completed_irregular":
      return "bg-pink c-white";
    default:
      return "";
  }
};

