import { Appointments } from "@devexpress/dx-react-scheduler-material-ui";

export const AppointmentCalendarComponent = ({ style, ...restProps }: any) => {
  if (restProps.data.status === "toBook")
    return (
      <Appointments.Appointment
        {...restProps}
        style={{ ...style, backgroundColor: "#cc0066" }}
      />
    );

  if (restProps.data.status === "selected")
    return (
      <Appointments.Appointment
        {...restProps}
        style={{ ...style, backgroundColor: "#5D6163" }}
      />
    );
  if (restProps.data.status === "pending" && restProps.data.title !== "Limpieza")
    return (
      <Appointments.Appointment
        {...restProps}
        style={{ ...style, backgroundColor: "#0099cc" }}
      />
    );

  if (restProps.data.status !== "pending" && restProps.data.title !== "Limpieza")
    return (
      <Appointments.Appointment
        {...restProps}
        style={{ ...style, backgroundColor: "#003366" }}
      />
    );

  if (restProps.data.title === "Limpieza")
    return (
      <Appointments.Appointment
        title={restProps.data.name}
        {...restProps}
        style={{ ...style, backgroundColor: "#dddddd" }}
      />
    );

  return <Appointments.Appointment {...restProps} style={style} />;
};
