import React, { FC } from "react";

//middleware
import { useSelector } from "react-redux";

//css
import "../App.css";
import ProtectedRoute from "../components/ProtectedRoute/ProtectedRoute";
//interfaces
import { IStore } from "../interfaces/IStore";
import { IUser } from "../interfaces/IUser";

//views
import { ROUTES } from "../utils/routes";
import { Checkin } from "../views/MobileView/checkin";

const CheckinRoute: FC = () => {
  //prettier-ignore
  let { token } = useSelector(({ user }: IStore) => user as IUser);

  // prettier-ignores
  return (
    <>
      <div className="container-fluid">
        <div className="row wrapper min-vh-100 flex-column flex-md-row ">
          <ProtectedRoute exact path={`${ROUTES.Mobile.Checkin}/:id`} component={Checkin} token={token} />
        </div>
      </div>
    </>
  );
};

export default CheckinRoute;
