import { IBookingRedux } from "../interfaces/IBookingRedux";
import { IUser } from "../interfaces/IUser";
import { TAction } from "./types";

export const SetUserAction = (user: IUser): TAction => ({
  type: "SET_USER",
  payload: user,
});

export const UnsetUserAction = (): TAction => ({
  type: "UNSET_USER",
});

export const LogoutAction = (): TAction => ({ type: "LOGOUT" });

export const SetBookingAction = (booking: IBookingRedux): TAction => ({
  type: "SET_BOOKING",
  payload: booking,
});

export const UnsetBookingAction = (): TAction => ({
  type: "UNSET_BOOKING",
});
