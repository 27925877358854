/* eslint-disable react-hooks/exhaustive-deps */

import {
  faBroom,
  faCalendar,
  faClock,
  faFileCsv,
  faFilePdf,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC, useEffect, useRef, useState } from "react";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CSVLink } from "react-csv";

//middleware

import Axios from "axios";
import { ROUTES } from "../../../utils/routes";
import { useHistory } from "react-router-dom";
import { bookingsToCsvList, csvTechnicianHeaders, OrderCsvByBooking, OrderCsvByStartDate } from '../../../utils/auxiliar/csv';
import { More } from "../../../components/More/More";
import { getAll, getBetweenTwoDates } from "../../../utils/requests/dynamicReq";
import { paths } from "../../../utils/paths";
import { IRoomTable } from "../../../interfaces/IRoom";
import { IBooking } from "../../../interfaces/IBooking";
import { WeeklyBookingList } from "../../../components/weeklyBookingList/weeklyBookingList";
import { dateForDDBB, datesNotValid, in7Days, today } from "../../../utils/constants";
import { locationKey } from "../../../enum/LocationType";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { TechnicianPdf } from "../../../components/Pdf/TechnicianPdf";
import { TechnicianPdf2 } from "../../../components/Pdf/TechnicianPdf2";
import { TechnicianPdf3 } from "../../../components/Pdf/TechnicianPdf3";

export const WeeklyBookings: FC = () => {
  const { BOOKINGSBYDATE, ROOMS } = paths;

  const history = useHistory();

  const isMountedRef = useRef(false);
  const source = Axios.CancelToken.source();

  const [technicalBookings, setTechnicalBookings] = useState<any[]>([]);
  const [displayTechnicalBookings, setDisplayTechnicalBookings] = useState<
    IBooking[]
  >([]);

  const [location, setLocation] = useState("");

  const [cvsData, setCSVData] = useState<any[]>([]);

  const [rooms, setRooms] = useState<IRoomTable[]>([]);
  const [allRooms, setAllRooms] = useState<IRoomTable[]>([]);
  const [room, setRoom] = useState("");
  const [message, setMessage] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [index, setIndex] = useState(10);

  const [startDate, setStartDate] = useState(new Date(today));
  //const [startDate, setStartDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(new Date(in7Days));

  const [showPdfLink, setShowPdfLink] = useState(false);


  useEffect(() => {
    setIsLoading(true);
    isMountedRef.current = true;
    loadData();
    loadRooms();
    return () => {
      isMountedRef.current = false;
      source.cancel();
    };
  }, []);

  const loadData = async () => {
    try {
      const response = await getBetweenTwoDates(
        BOOKINGSBYDATE,
        "fromDate",
        dateForDDBB(startDate),
        "toDate",
        dateForDDBB(endDate),
        source
      );

      setTimeout(() => setShowPdfLink(true), 1000);

      if (isMountedRef.current) {
        setTechnicalBookings(response);
        setDisplayTechnicalBookings(response);
         // EXPORT CSV
         let csv = bookingsToCsvList(response);
         setCSVData(OrderCsvByStartDate(csv));
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log("HTTP call cancelled");
    }
  };

  const loadRooms = async () => {
    try {
      const rooms = await getAll(ROOMS, source);
      // console.log(rooms);

      if (isMountedRef.current) {
        composeRooms(rooms);

        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log("HTTP call cancelled");
    }
  };

  const composeRooms = (response: any) => {
    let allRooms: IRoomTable[] = [];
    response.map(({ id, zone, name, active }: any) =>
      allRooms.push({
        id,
        room: name,
        zone: zone.name,
        location: zone.location,
        active,
      })
    );
    setAllRooms(allRooms);
    setRooms(allRooms);
  };

  const filterLocation = (location: string) => {
    setLocation(location);
    let room = "";
    setRoom(room);
    setRooms(displayByLocation(allRooms, location));
    multipleFilter(room, location);
  };

  const displayByLocation = (response: any, pickedLocation: string) => {
    // console.log(response);
    // console.log(pickedLocation);
    const rooms = response?.filter(
      ({ location }: any) => location === pickedLocation
    );
    return rooms;
  };

  const filterRoom = (room: string) => {
    setRoom(room);
    multipleFilter(room, location);
  };

  const multipleFilter = (pickedRoom: string, pickedLocation: string) => {
    let filteredBookings = technicalBookings.filter(({ room, metaBooking }: any) => {
        return (
          (pickedRoom === "" || room?.name === pickedRoom) &&
          (pickedLocation === "" || metaBooking?.location === pickedLocation)
        );
      })
      setDisplayTechnicalBookings(filteredBookings);

      // EXPORT CSV
      let csv = bookingsToCsvList(filteredBookings);
      setCSVData(OrderCsvByStartDate(csv));
  };

  const handleSubmit = () => {
    if (endDate < startDate) {
      setMessage(datesNotValid);
      setTimeout(() => setMessage(""), 4000);
    }else {
      loadData();
    }
  };

  return (
    <>
      <div className="container-fluid pl-4">
        <div className="row mb-4">
          <div className="col-9">
            <h5>Reserva</h5>
          </div>
          <div className="col-sm-2 col-12">
            <button
              className="button-search  mb-4"
              onClick={() => history.push(`${ROUTES.Common.Availability}`)}
            >
              <FontAwesomeIcon className=" mr-2 " icon={faCalendar} />
              Ver Calendario
            </button>
          </div>
          {/* <div className="col-1">
            <CSVLink
              data={cvsData}
              headers={csvTechnicianHeaders}
              filename={`Reservas-Centro-Experimentacion-de-${moment(startDate)
                .local()
                .format("DD-MM-YYYY")}-a-${moment(endDate)
                  .local()
                  .format("DD-MM-YYYY")}.csv`}
            >
              <button className="smallBtn ml-1 mr-1" style={{ width: "40px" }}>
                <FontAwesomeIcon
                  color="green"
                  style={{ fontSize: "24px" }}
                  icon={faFileCsv}
                />
              </button>
            </CSVLink>
          </div> */}

  {/* <div className="row justify-content-end"> */}

{/* Descomentar para abajo */}
        <div >
            {showPdfLink && (
              <PDFDownloadLink
                document={<TechnicianPdf2 bookings={cvsData} startDate={startDate.toLocaleDateString("es-ES")} endDate={endDate.toLocaleDateString("es-ES")} room={room} location={location} />}
                fileName={`Reservas-${startDate.toLocaleDateString("es-ES")}-a-${endDate.toLocaleDateString("es-ES")}`}
              >
                {({ blob, url, loading, error }) => (
                  <>
                   {loading ? (
                    <span className="spinner-border spinner-border-sm" />
                  ) : (
                  <button
                  className="smallBtn ml-1 mr-1"
                  style={{ width: "40px" }}
                  >
                      <FontAwesomeIcon
                        color="red"
                        style={{ fontSize: "24px" }}
                        icon={faClock}
                      />
                 
                  </button>
                  )}
                  </>
                )}
              </PDFDownloadLink>
            )}
            
            {showPdfLink && (
              <PDFDownloadLink
             document={<TechnicianPdf3 bookings={cvsData} startDate={startDate.toLocaleDateString("es-ES")} endDate={endDate.toLocaleDateString("es-ES")} room={room} location={location} />}
             fileName={`Limpieza-${startDate.toLocaleDateString("es-ES")}-a-${endDate.toLocaleDateString("es-ES")}`}
            >
                {({ blob, url, loading, error }) => (
                  <>
                   {loading ? (
                    <span className="spinner-border spinner-border-sm" />
                    ) : (
                  <button
                    className="smallBtn ml-1 mr-1"
                    style={{ width: "40px" }}
                  >
                      <FontAwesomeIcon
                        color="green"
                        style={{ fontSize: "24px" }}
                        icon={faBroom}
                        />
                 
                  </button>
                  )}
                  </>
                )}
              </PDFDownloadLink>
            )}
          </div>
            </div>

        <div className="row">
          <div className="col-sm-2 col-12">
            <DatePicker
              className="form-control"
              selected={startDate!}
              onChange={(date: Date) => setStartDate(date!)}
              placeholderText="Día actual"
              dateFormat="dd/MM/yyyy"
              locale="es"
            />
          </div>
          <div className="col-sm-2 col-12">
            <DatePicker
              className="form-control"
              selected={endDate ? endDate : startDate}
              onChange={(date: Date) => setEndDate(date!)}
              placeholderText="Día actual"
              dateFormat="dd/MM/yyyy"
              locale="es"
            />
          </div>

          <div className="col-sm-2 col-12">
            <button className="button-search mb-4" onClick={handleSubmit}>
              <FontAwesomeIcon className=" mr-2 " icon={faCalendar} />
              Enviar
            </button>
          </div>

          <div className="col-sm-2 col-12">
            <select
              className="form-control"
              value={location}
              onChange={({ target: { value } }) => filterLocation(value)}
            >
              <option value={""}>Todas</option>
              <option value={locationKey.ceca}>{locationKey.ceca}</option>
              <option value={locationKey.medicina}>{locationKey.medicina}</option>
            </select>
          </div>

          <div className="col-sm-3 col-12">
            <select
              className="form-control"
              disabled={!location}
              value={room}
              onChange={({ target: { value } }) => filterRoom(value)}
            >
              <option value={""}>Salas</option>
              {rooms.map(({ room, id }) => (
                <option key={id} value={room}>
                  {room}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="row mt-1">
          <div className="col-sm-6 col-12 text-danger">
            <span>{message}</span>
          </div>
        </div>

        {/* Se le pasa un array de objetos. Se carga dependiendo si el user es admin o no [TENER EN CUENTA SI ES TÉCNICX]*/}
        <div className="row mt-4">
          <div className="col-12 whiteDiv overFlowAuto">
            <WeeklyBookingList
              bookings={displayTechnicalBookings}
              index={index}
            />

            <More
              data={displayTechnicalBookings}
              displayData={displayTechnicalBookings}
              isLoading={isLoading}
              setIndex={setIndex}
              index={index}
            />
          </div>
        </div>
      </div>
    </>
  );
};
