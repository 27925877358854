//middleware
import React from "react";
import { useSelector } from "react-redux";
import ProtectedRoute from "../components/ProtectedRoute/ProtectedRoute";
import { IStore } from "../interfaces/IStore";
import { IUser } from "../interfaces/IUser";

//views
import { ROUTES } from "../utils/routes";
import { DailyEquipments } from "../views/Logged/Technician/dailyEquipments";
import { WeeklyBookings } from "../views/Logged/Technician/weeklyBookings";

const Technician = () => {
  // prettier-ignore
  let { token } = useSelector(({ user }: IStore) => user as IUser);

  return (
    <>
    <ProtectedRoute exact path={ROUTES.Technician.DailyEquipments} component={DailyEquipments} token={token} />
    <ProtectedRoute exact path={ROUTES.Technician.WeeklyBookings} component={WeeklyBookings} token={token} />
   
    </>
  );
};

export default Technician;
