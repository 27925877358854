import Swal from "sweetalert2";
import { IEquipment } from "../../interfaces/IEquipment";
import { dateToDDBB } from "./dates";

export const equipmentIds = (equipments: IEquipment[]) =>
  equipments.map(({ id }) => id!);

  export const availabilityCriteria = (startDate: Date, endDate: Date, location: string, bookingId: number) => ({
    startDate: dateToDDBB(startDate!),
    endDate: dateToDDBB(endDate!),
    location,
    bookingId: bookingId
  });

export const checkIfStillAvailable = (
  equipments: IEquipment[],
  bookingEquipment: IEquipment[],
  setBookingEquipment: any,
  isMountedRef: any
) => {
  //prettier-ignore
  const noLongerAvailable = bookingEquipment.filter(({ id: id1 }: any) => !equipments.some(({ id: id2 }) => id2 === id1));
  //prettier-ignore
  const stillAvailable = bookingEquipment.filter(({ id: id1 }: any) => equipments.some(({ id: id2 }) => id2 === id1));

  if (noLongerAvailable.length && isMountedRef.current) {
    setBookingEquipment(stillAvailable);
    let notAvailableNames = noLongerAvailable.map(({ name }) => ` "${name}"`);
    Swal.fire({
      icon: "warning",
      text: `Los siguientes instrumentales no están disponibles para la fecha/hora seleccionadas y se han quitado: ${notAvailableNames}`,
    });
  }
};
