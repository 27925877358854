import React from "react";
import { View, StyleSheet } from "@react-pdf/renderer";
import { ICsvBooking } from "../../../interfaces/ICsvBooking";
import { BookingsTableRow3 } from "./BookingsTableRow3";
import { BookingsTableHeader3 } from "./BookingsTableHeader3";

// Create styles
const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 24,
    borderWidth: 1,
    borderColor: "#242424",
  },
});

interface IProps {
  bookings: ICsvBooking[];
}

// Create Document Component
export const TableContainer3 = ({ bookings }: IProps) => {
  return (
    <View style={styles.tableContainer}>
      <BookingsTableHeader3 />
      <BookingsTableRow3 bookings={bookings} />
    </View>
  );
};
