/* eslint-disable react-hooks/exhaustive-deps */

//middleware
import Axios from "axios";
import { IMetaBooking } from "../../interfaces/IMetaBooking";
import { useHistory } from "react-router";
import { ROUTES } from "../../utils/routes";
import { switchStatus } from "../../utils/status";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { paths } from "../../utils/paths";
import { useSelector } from "react-redux";
import { IStore } from "../../interfaces/IStore";
import { IUser } from "../../interfaces/IUser";
import Swal from "sweetalert2";
import { Delete } from "../../utils/requests/dynamicReq";
import { SwalError } from "../../utils/constants";
import { formatDate } from "../../utils/auxiliar/dates";

interface IProps {
  metaBookings: IMetaBooking[];
  index: number;
}

export const MetaBookingList = ({ metaBookings, index }: IProps) => {

  let { isAdmin } = useSelector(({ user }: IStore) => user as IUser);
  const { METABOOKINGS } = paths;

  const source = Axios.CancelToken.source();
  const history = useHistory();

  const deleteIt = (id: number) => {
    Swal.fire({
      icon: "question",
      text: "¿Seguro quieres eliminarl@? Este proceso es irreversible.",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "No",
    }).then(async ({ isConfirmed }) => {
      if (isConfirmed) {
        try {
          Delete(METABOOKINGS, id, source).then(() => {
            Swal.fire({ icon: "success", text: "Eliminada correctamente" });
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          });
        } catch (error) {
          Swal.fire({ icon: "error", text: SwalError });
        }
      }
    });
  };

  return (
    <>
      <table className="table table-hover text-center">
        <thead className="bg-black text-light">
          <tr>
            <th scope="col">Reserva</th>
            <th scope="col">Fecha Inicio</th>
            <th scope="col">Estado</th>
            <th scope="col">Instalación</th>
            <th scope="col">Animal</th>
            <th scope="col">Ip</th>
            {isAdmin && <th scope="col">Datos Inv.</th>}
            <th scope="col">Info Reserva</th>
            <th scope="col">Eliminar</th>
          </tr>
        </thead>
        <tbody className="bg-fffff">
          {
            //prettier-ignore
            metaBookings
            ?.slice(0, index)
            ?.map(({ id, status, location, animal, user, ip, bookings }: IMetaBooking) => (
              <tr key={id}>
                <td><strong>{`#${id}`} </strong></td>
                <td>{formatDate(bookings![0]?.startDate)}</td>
                <td>{switchStatus(status)} </td>
                <td>{location} </td>
                <td>{animal.name} </td>
                <td>{`${ip.name} ${ip.surname}`} </td>
                {isAdmin && (
                  <td>{`${user.name} ${user.surname1} ${user.surname2}`}</td>
                )}
                <td onClick={() => history.push(`${ROUTES.Common.MetaBookings}/${id}`)}  >
                  <strong className="pointer c-lightblue">Ver</strong>
                </td>
                <td>
                  {((!isAdmin && status === "pending") || isAdmin) && (
                    <div onClick={() => deleteIt(id!)}>
                      <FontAwesomeIcon
                        className="pointer"
                        color="red"
                        icon={faTrash}
                      />
                    </div>
                  )}
                </td>
              </tr>
            ))
          }
        </tbody>
      </table>
    </>
  );
};
