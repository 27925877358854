import moment from "moment";

export const formatDate = (date: Date | string) => moment(date).format("DD/MM/YYYY");

export const formatTime = (dateTime: Date | string) => moment(dateTime).format("HH:mm");

export const formatDateTime = (dateTime: Date) => moment(dateTime).format("YYYY-MM-DD HH:mm:ss");

//prettier-ignore
export const dateToDDBB = (date: Date) => moment(date).utc().format("YYYY-MM-DD HH:mm:ss");

//prettier-ignore
export const utcToLocalTime = (date: Date) => moment.utc(date).local().format("YYYY-MM-DD HH:mm:ss");

//prettier-ignore
export const tableLocalTime = (date: Date) => moment.utc(date).local().format("YYYY-MM-DD HH:mm");

export const extractYear = (date: Date) => moment.utc(date).local().format("YYYY");
