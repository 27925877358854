/* eslint-disable eqeqeq */
import { ICalendarBooking } from "../../interfaces/ICalendarBooking";
import moment from "moment";
import produce from "immer";
import { utcToLocalTime } from "./dates";
import { IBooking } from "../../interfaces/IBooking";
import {  tomorrow } from "../constants";

export const showCalendarBooking = (bookings: any[]) => {
  let calendarBooking: ICalendarBooking[] = bookings.map(
    ({
      id,
      users,
      room,
      checkInStatus,
      externalUser1,
      externalUser2,
      startDate,
      endDate,
      additionalInfo,
      equipments,
      metaBooking,
      cleaningEndDate,
    }) => {
      return {
        id,
        location: metaBooking?.location,
        room,
        checkInStatus,
        externalUser1,
        externalUser2,
        users,
        title: `${room.name} - ${metaBooking?.user.name} ${metaBooking?.user?.surname1} ${metaBooking?.user?.surname2}`,
        additionalInfo,
        equipments,
        startDate: utcToLocalTime(startDate),
        endDate: utcToLocalTime(endDate),
        status: metaBooking?.status,
        user: metaBooking?.user,
        cleaningEndDate: utcToLocalTime(cleaningEndDate),
      };
    }
  );

  return calendarBooking;
};

export const addCleaning = (bookings: ICalendarBooking[]) => {
  let newBooking: ICalendarBooking[] = [];
  let newObj: any = {};

  // eslint-disable-next-line array-callback-return
  bookings.map((i: ICalendarBooking, index) => {
    newObj = {
      id: new Date().getTime() + index,
      title: "Limpieza",
      startDate: i?.endDate,
      endDate: i.cleaningEndDate,
      bookingIdCleaning: i.id,
    };
    newBooking.push({ ...newObj });
  });

  return newBooking;
};

export const buildFullBookings = (availability: any[]) => {
  let rawBookings = showCalendarBooking(availability);
  let cleaning = addCleaning(rawBookings);
  let bookingsWithCleaning = rawBookings.concat(cleaning);
  return bookingsWithCleaning;
};

export const removeBooking = (setBookings: any) => {
  setBookings((state: ICalendarBooking[]) =>
    produce(state, (drafState) =>
      drafState.filter(({ draftBooking }) => draftBooking !== true)
    )
  );
};

export const addBooking = (
  setBookings: any,
  name: string,
  startDate: Date,
  endDate: Date
) => {
  setBookings((state: ICalendarBooking[]) =>
    produce(state, (drafState) => {
      drafState.push({
        id: new Date().getTime(),
        title: name,
        status: "toBook",
        draftBooking: true,
        startDate: startDate?.toString(),
        endDate: endDate.toString(),
      });
      drafState.push({
        id: +moment(new Date()).add(1, "s"),
        title: "Limpieza",
        draftBooking: true,
        startDate: endDate.toString(),
        endDate: moment(endDate).add(30, "m").toString(),
      });
    })
  );
};

export const highlightBooking = (
  setBookings: any,
  drafBookings: IBooking[],
  booking: IBooking
) => {
  setBookings((state: ICalendarBooking[]) =>
    produce(state, (drafState) => {
      if (!state.length) return;
      let index = drafBookings.findIndex(({ id }) => id === booking.id);
      drafState[index].status = "toBook";
    })
  );
};

//prettier-ignore
export const draftEraseBooking = (setBookings: any, bookingId: number, setBookingToEdit: any) => {
  setBookingToEdit(false);
  setBookings((state: ICalendarBooking[]) =>
    produce(state, (drafState) =>
      drafState.filter((booking) => booking.id != +bookingId)
    )
  );
  draftEraseCleaning(setBookings, bookingId);
};

export const draftEraseCleaning = (setBookings: any, bookingId: number) => {
  setBookings((state: ICalendarBooking[]) =>
    produce(state, (drafState) =>
      drafState.filter((booking) => booking.bookingIdCleaning != +bookingId)
    )
  );
};

export const minBookingDate = (isAdmin?: boolean) =>
  isAdmin ? new Date() : new Date(tomorrow);
