/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

//middleware
import Axios from "axios";
import moment from "moment";
import {
  faArrowLeft,
  faCheckDouble,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { BookingList } from "../../../components/BookingList/BookingList";
import { More } from "../../../components/More/More";
import { paths } from "../../../utils/paths";
import { IUser } from "../../../interfaces/IUser";
import { IBooking } from "../../../interfaces/IBooking";
import { getById, update } from "../../../utils/requests/dynamicReq";
import { switchStatus, switchStatusColor } from "../../../utils/status";
import Swal from "sweetalert2";
import { SwalError } from "../../../utils/constants";
import { ROUTES } from "../../../utils/routes";
import { useSelector } from "react-redux";
import { IStore } from "../../../interfaces/IStore";

export const Bookings: any = () => {
  let { isAdmin } = useSelector(({ user }: IStore) => user as IUser);
  const history = useHistory();
  const { id }: any = useParams();

  const { METABOOKINGS } = paths;

  const isMountedRef = useRef(false);
  const source = Axios.CancelToken.source();

  const [bookings, setBookings] = useState<IBooking[]>([]);
  const [displayBookings, setDisplayBookings] = useState<IBooking[]>([]);
  const [user, setUser] = useState<IUser>();
  const [status, setStatus] = useState("");
  const [index, setIndex] = useState(6);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    isMountedRef.current = true;
    loadData();
    return () => {
      isMountedRef.current = false;
      source.cancel();
    };
  }, []);

  const loadData = async () => {
    try {
      const { bookings, user, status } = await getById(METABOOKINGS, id, source);

      if (isMountedRef.current) {
        setDisplayBookings(bookings);
        setBookings(bookings);
        setUser(user);
        isAdmin ? setStatus(status) : changeUserStatus(status);
        setIsLoading(false);
      }
    } catch (error) {
      if (isMountedRef.current) {
        setIsLoading(false);
        console.log("HTTP call cancelled");
      }
    }
  };

  const changeUserStatus = (status: string) => {
    const newStatus = "completed";
    if (status === "completed_irregular") {
      setStatus(newStatus);
    } else {
      setStatus(status);
    }
  };

  const refreshIndex = (number: number) => {
    //TODO - Chequear que esta función "funciona"
    setIndex(number);
    bookings?.slice(0, number);
  };

  //--------------------------------

  const handleSubmit = () => {
    Swal.fire({
      icon: "question",
      text: `¿Quieres confirmar esta reserva?`,
      showCancelButton: true,
      confirmButtonText: "Si",
      cancelButtonText: "No",
    }).then(({ isConfirmed }) => isConfirmed && handleConfirmation());
  };

  const handleConfirmation = () => {
    update(METABOOKINGS, +id, { status: "confirmed" }, source)
      .then(() => {
        Swal.fire({ icon: "success", text: `Reserva confirmada` });
        loadData();
      })
      .catch(() => Swal.fire({ icon: "error", text: SwalError }));
  };

  const editFilters = () => {
    return (
      <>
        <div className="col-sm-8 col-12 d-flex justify-content-between mt-1 mb-2">
          <button
            className="button-search d-flex justify-content-around align-items-center"
            onClick={() => history.push(ROUTES.Common.MetaBookings)}
          >
            <FontAwesomeIcon icon={faArrowLeft} />
            Atrás
          </button>
        </div>

        <div className="col-sm-4 col-12 d-flex justify-content-around mt-1 mb-2">
          {isAdmin && (status === "pending" || status === "irregular") && (
            <button className="button-search" onClick={handleSubmit}>
              <FontAwesomeIcon className=" mr-2 " icon={faCheckDouble} />
              Confirmar
            </button>
          )}
          {(status === "pending" || isAdmin) && (
            <button
              className="button-search d-flex justify-content-around align-items-center"
              onClick={() => history.push(`${ROUTES.Common.NewBooking}/${id}`)}
            >
              <FontAwesomeIcon icon={faPlus} />
              Añadir reserva
            </button>
          )}
        </div>
      </>
    );
  };

  const header = () => {
    return (
      <>
        <div className="col-sm-10 col-12 ">
          <h5>
            Reserva {`#${moment().year()}-${id}`}.{" "}
            {`${user?.name!} ${user?.surname1!} ${user?.surname2!}`}
          </h5>
        </div>
        <div className={`${switchStatusColor(status)} statusDiv col-sm-2 col-12`}>
          <h5>{switchStatus(status!)}</h5>
        </div>
      </>
    );
  };

  return (
    <>
      {/* Se le pasa un array de objetos */}
      <div className="container-fluid pl-4">
        <div className="row">{header()}</div>
        <div className="row mt-4">{editFilters()}</div>
        <div className="row mt-4">
          <div className="col-12 whiteDiv overFlowAuto">
            <BookingList bookings={bookings} metabookingStatus={status} user={user}  />
            <More
              data={bookings}
              displayData={displayBookings}
              isLoading={isLoading}
              setIndex={refreshIndex}
              index={index}
            />
          </div>
        </div>
      </div>
    </>
  );
};



// /* eslint-disable react-hooks/exhaustive-deps */
// import React, { useEffect, useRef, useState } from "react";
// import { useHistory, useParams } from "react-router-dom";

// //middleware
// import Axios from "axios";
// import moment from "moment";
// import {
//   faArrowLeft,
//   faCheckDouble,
//   faPlus,
// } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// import { BookingList } from "../../../components/BookingList/BookingList";
// import { More } from "../../../components/More/More";
// import { paths } from "../../../utils/paths";
// import { IUser } from "../../../interfaces/IUser";
// import { IBooking } from "../../../interfaces/IBooking";
// import { getById, update } from "../../../utils/requests/dynamicReq";
// import { switchStatus, switchStatusColor } from "../../../utils/status";
// import Swal from "sweetalert2";
// import { SwalError } from "../../../utils/constants";
// import { useSelector } from "react-redux";
// import { IStore } from "../../../interfaces/IStore";

// export const Bookings: any = () => {
//   let { isAdmin } = useSelector(({ user }: IStore) => user as IUser);
//   const history = useHistory();
//   const { id }: any = useParams();

//   const { METABOOKINGS } = paths;

//   const isMountedRef = useRef(false);
//   const source = Axios.CancelToken.source();

//   const [bookings, setBookings] = useState<IBooking[]>([]);
//   const [displayBookings, setDisplayBookings] = useState<IBooking[]>([]);
//   const [user, setUser] = useState<IUser>();
//   const [status, setStatus] = useState("");
//   const [index, setIndex] = useState(6);
//   const [isLoading, setIsLoading] = useState(false);
//   const [isFromInbox, setIsFromInbox] = useState(false);

//   useEffect(() => {
//     setIsLoading(true);
//     isMountedRef.current = true;
//     checkIfIsFromInbox();
//     loadData();
//     return () => {
//       isMountedRef.current = false;
//       source.cancel();
//     };
//   }, []);

//   const checkIfIsFromInbox = () => {
//     const { pathname }: any = history.location;
//     if (pathname.includes("buzon")) {
//       setIsFromInbox(true);
//     }
//   };

//   const loadData = async () => {
//     try {
//       const { bookings, user, status } = await getById(METABOOKINGS, id, source);

//       if (isMountedRef.current) {
//         console.log(bookings);
//         setDisplayBookings(bookings);
//         setBookings(bookings);
//         setUser(user);
//        isAdmin? setStatus(status) : changeUserStatus(status);
//         setIsLoading(false);
//       }
//     } catch (error) {
//       setIsLoading(false);
//       console.log("HTTP call cancelled");
//     }
//   };

//   const changeUserStatus = (status: string) => {
//     const newStatus = "completed";
//     if (status === "completed_irregular"){
//       setStatus(newStatus);
//     }else{
//       setStatus(status);
//     }
//   }

//   const refreshIndex = (number: number) => {
//     //TODO - Chequear que esta función "funciona"
//     setIndex(number);
//     bookings?.slice(0, number);
//   };

//   const editFilters = () => {
//     if (isFromInbox && isAdmin && (status === "pending" || status === "irregular")) {
//       return (
//         <>
//           <div className="col-sm-3 col-12 mt-1 mb-2">
//             <button className="button-search " onClick={() => history.goBack()}>
//               <FontAwesomeIcon className=" mr-4 " icon={faArrowLeft} />
//               Atrás
//             </button>
//           </div>
//           <div className="col-sm-3 col-12" />
//           <div className="col-sm-3 col-12 " />

//           <div className="col-sm-3 col-12 mt-1 mb-2">
//             <button className="button-search" onClick={handleSubmit}>
//               <FontAwesomeIcon className=" mr-2 " icon={faCheckDouble} />
//               Confirmar
//             </button>
//           </div>
//         </>
//       );
//     } else {
//       return (
//         <>
//           <div className="col-sm-3 col-12">
//             <button className="button-search" onClick={() => history.goBack()}>
//               <FontAwesomeIcon className=" mr-2 " icon={faArrowLeft} />
//               Atrás
//             </button>
//           </div>
//           <div className="col-sm-6 col-12" />
//           <div className="col-sm-3 col-12">
//             <button className="button-search" onClick={() => history.goBack()}>
//               <FontAwesomeIcon className=" mr-2 " icon={faPlus} />
//               Añadir reserva
//             </button>
//           </div>
//         </>
//       );
//     }
//   };

//   const header = () => {
//     return (
//       <>
//         <div className="col-sm-8 col-12 ">
//           <h5>
//             Reserva {`#${moment().year()}-${id}`}.{" "}
//             {`${user?.name!} ${user?.surname1!} ${user?.surname2!}`}
//           </h5>
//         </div>
//         <div className={`${switchStatusColor(status)} statusDiv col-sm-2 col-12`}>
//           <h5>{switchStatus(status!)}</h5>
//         </div>
//       </>
//     );
//   };

//   //--------------------------------

//   const handleSubmit = () => {
//     Swal.fire({
//       icon: "question",
//       text: `¿Quieres editar esta reserva?`,
//       showCancelButton: true,
//       confirmButtonText: "Si",
//       cancelButtonText: "No",
//     }).then(({ isConfirmed }) => {
//       isConfirmed && handleConfirmation();
//     });
//   };

//   const handleConfirmation = () => {
//     let confirmedStatus = "confirmed";
//     update(METABOOKINGS, +id, { status: confirmedStatus }, source)
//       .then(async () => {
//         Swal.fire({
//           icon: "success",
//           text: `Reserva editada correctamente.`,
//         });

//         if (isMountedRef.current) {
//           loadData();
//         }
//       })
//       .catch(() => {
//         Swal.fire({
//           icon: "error",
//           text: SwalError,
//         });
//       });
//   };

//   return (
//     <>
//       {/* Se le pasa un array de objetos */}
//       <div className="container-fluid pl-4">
//         <div className="row mb-4">{header()}</div>

//         <div className="row">{editFilters()}</div>

//         <div className="row mt-4">
//           <div className="col-12 whiteDiv" style={{ overflowX: "auto" }}>
//             <BookingList bookings={bookings} metabookingStatus ={status} />
//             <More
//               data={bookings}
//               displayData={displayBookings}
//               isLoading={isLoading}
//               setIndex={refreshIndex}
//               index={index}
//             />
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };
