//calendar
import Paper from "@material-ui/core/Paper";
import {
  Scheduler,
  WeekView,
  DateNavigator,
  Toolbar,
  Appointments,
  TodayButton,
  AppointmentTooltip,
} from "@devexpress/dx-react-scheduler-material-ui";
import { ViewState } from "@devexpress/dx-react-scheduler";
import { ICalendarBooking } from "../../interfaces/ICalendarBooking";
import { AppointmentCalendarComponent } from "./AppointmentCalendarComponent";
import { AppointmentTooltipComponent } from "./AppointmentTooltipComponent";

interface IProps {
  height: number;
  bookings: ICalendarBooking[];
  date: Date;
  route: string;
}

export const Calendar = ({ bookings, height, date, route }: IProps) => {
  let isNewBooking = route !== "availability";

  return (
    <>
      <div className="row mt-2">
        <div className="col-12">
          <Paper>
            <Scheduler
              data={bookings}
              height={height}
              firstDayOfWeek={1}
              locale={"es-Es"}
            >
              {!isNewBooking ? (
                <ViewState defaultCurrentDate={date} />
              ) : (
                <ViewState currentDate={date} />
              )}
              <WeekView startDayHour={8} endDayHour={20} />
              {!isNewBooking && <Toolbar />}
              {!isNewBooking && <DateNavigator />}
              {!isNewBooking && <TodayButton />}
              <Appointments appointmentComponent={AppointmentCalendarComponent} />
              <AppointmentTooltip
                showCloseButton
                contentComponent={AppointmentTooltipComponent}
              />
            </Scheduler>
          </Paper>
        </div>
      </div>
    </>
  );
};
