/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";

//middleware
import Axios from "axios";
import { formatDate, formatTime } from "../../utils/auxiliar/dates";
import moment from "moment";
import { IEquipment } from "../../interfaces/IEquipment";
import { EquipmentModal } from "../Modals/EquipmentModal";

interface IProps {
  bookings: any[];
  index: number;
}

export const WeeklyBookingList = ({ bookings, index }: IProps) => {
  // console.log(bookings);

  const isMountedRef = useRef(false);
  const source = Axios.CancelToken.source();
 

  const [equipments, setEquipments] = useState<IEquipment[]>([]);
  const [showEquipment, setShowEquipment] = useState(false);

  const openEquipment = (equipments: IEquipment[]) => {
    setEquipments(equipments);
    setShowEquipment(true);
  };

  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
      source.cancel();
    };
  }, []);

  return (
    <>
      <table className="table table-hover text-center">
        <thead className="bg-black text-light">
          <tr>
            <th scope="col">Código reserva</th>
            <th scope="col">Fecha</th>
            <th scope="col">Horario</th>
            <th scope="col">Sala</th>
            <th scope="col">Datos Inv.</th>
            <th scope="col">Instrumental</th>
          </tr>
        </thead>
        <tbody className="bg-fffff">
          {bookings
            ?.slice(0, index)
            ?.map(
              ({
                id,
                startDate,
                endDate,
                room,
                metaBooking,
                equipments,
              }: any) => (
                <tr key={id}>
                  <td>
                    <strong>{`#${moment().year()}-${id}`} </strong>
                  </td>
                  <td>{formatDate(startDate)}</td>
                  <td>{`${formatTime(startDate)} - ${formatTime(endDate)}`}</td>
                  <td>{room?.name}</td>
                  <td>{`${metaBooking.user.name} ${metaBooking.user.surname1} ${metaBooking.user?.surname2}`}</td>
                  <td
                    className="pointer c-lightblue"
                    onClick={() => openEquipment(equipments)}
                  >
                    <strong>Ver</strong>
                  </td>
                </tr>
              )
            )}
        </tbody>
      </table>
      <EquipmentModal
        show={showEquipment}
        onHide={() => setShowEquipment(false)}
        equipments={equipments}
      />
    </>
  );
};