import moment from "moment";
import { role } from "./roles";

export const jwtPassword = process.env.REACT_APP_JWT_PSS as string;

export const cecaURL = "https://admin.ceca.uma.es/";

export const baseURL = process.env.REACT_APP_API || "http://localhost:3000/";

export const SwalError =
  "Ha habido un error, por favor intente de nuevo más tarde o contacte con su administrador.";
export const fillRequiredText = "Por favor rellene rellene los campos necesarios";
export const requiredInputs = "Por favor, rellene todos los campos con *";

//dates
export const today = moment().format("YYYY-MM-DD");
export const tomorrow = moment().add(1, "days").toString();
export const in7Days = moment().add(7, "days").format("YYYY-MM-DD");
export const SixMonthsAfter = moment().add(6, "months").toString();

export const dateForDDBB = (date: Date | string) => {
  return moment(date).format("YYYY-MM-DD");
};

export const SwalCheckInError =
  "El proceso de check-in no se ha realizado correctamente. Contacte con administración para conocer los detalles";

export const datesNotValid = "La fecha de fin no puede ser menor a la de inicio."; 

export const switchRole =( userRole: string) =>{
  switch (userRole) {
    case role.admin:
      return "Admin";
    case role.technician:
      return "Técnico";
    case role.user:
      return "Usuario";
    default:
      return "";
  }
};