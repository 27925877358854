/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect } from "react";
import "./App.css";

//middleware
import { useDispatch, useSelector } from "react-redux";
import { verify } from "jsonwebtoken";
import { Router, Switch, Route } from "react-router-dom";

//interfaces
import { IStore } from "./interfaces/IStore";
import { IUser } from "./interfaces/IUser";

//aux
import history from "./utils/history";
import { SetUserAction } from "./redux/actions";
import { jwtPassword } from "./utils/constants";

//views
import Login from "./views/Unlogged/Login";
// import { role } from "./utils/roles";
import LoggedRoutes from "./routes";
import { role } from "./utils/roles";

const App: FC = () => {
  let { token } = useSelector(({ user }: IStore) => user as IUser);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!token) {
      const localStorageToken = localStorage.getItem("token");
      localStorageToken && resetUser(localStorageToken);
    }
  }, [token]);

  const resetUser = (localStorageToken: string) => {
    try {
      //if token exists and is valid
      const {
        userId,
        userRole,
        name,
        surname1,
        surname2,
        email,
        phone,
        ipId
      }: any = verify(localStorageToken, jwtPassword);
      dispatch(
        SetUserAction({
          token: localStorageToken,
          id: userId,
          userRole,
          isAdmin: userRole === role.admin,
          name,
          surname1,
          surname2,
          email,
          phone,
          ipId
        })
      );
    } catch {
      //if token was fiddled with
      localStorage.removeItem("token");
      history.push("/");
    }
  };

  return (
    <Router history={history}>
      <Switch>
        {! token && <Route exact path="/" component={Login} />}
       <LoggedRoutes />
      </Switch>
    </Router>
  );
};

export default App;
