/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

//middleware
import Axios from "axios";
import { Calendar } from "../../../components/Calendar/Calendar";
import { paths } from "../../../utils/paths";
import { IStore } from "../../../interfaces/IStore";
import { IUser } from "../../../interfaces/IUser";
import { ICalendarBooking } from "../../../interfaces/ICalendarBooking";
import { getAll, getByFilter } from "../../../utils/requests/dynamicReq";
import {
  addCleaning,
  showCalendarBooking,
} from "../../../utils/auxiliar/bookingsFilters";
import { selectRoomsByZone2 } from "../../../utils/auxiliar/roomsFilters";
import { selectZonesByLocation } from "../../../utils/auxiliar/roomsFilters";
import moment from "moment";
import { locationKey } from "../../../enum/LocationType";

export const Availability: FC = () => {
  const { BOOKINGS, ZONES, ROOMS } = paths;

  let { isAdmin } = useSelector(({ user }: IStore) => user as IUser);

  const [totalBookings, setTotalBookings] = useState<ICalendarBooking[]>([]);

  const [rawRooms, setRawRooms] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [rawZones, setRawZones] = useState([]);
  const [zones, setZones] = useState([]);

  const [room, setRoom] = useState("");
  const [zone, setZone] = useState("");
  const [location, setLocation] = useState("");


  const isMountedRef = useRef(false);
  const source = Axios.CancelToken.source();

  useEffect(() => {
    isMountedRef.current = true;
    loadData();
    return () => {
      isMountedRef.current = false;
      source.cancel();
    };
  }, []);

  const loadData = async () => {
    try {
      const rooms = await getAll(ROOMS, source);
      const zones = await getAll(ZONES, source);

      if (isMountedRef.current) {
        setRawZones(zones);
        setRawRooms(rooms);

        changedLocation(zones[0]?.location, zones, rooms);

      }
    } catch (error) {
      console.log("HTTP call cancelled");
    }
  };

  const loadBookingsByZone = async (zoneId: number) => {
    try {

      const response = await getByFilter(BOOKINGS, "zoneId", zoneId, source);

      if (isMountedRef.current) {
        creatingCalendarBookings(response);
      }
    } catch (error) {
      console.log("HTTP call cancelled");
    }
  };

  const loadBookingsByRoom = async (roomId: number) => {
    try {

      let response;

      roomId === 0 ? response = await getByFilter(BOOKINGS, "zoneId", parseInt(zone), source) : response = await getByFilter(BOOKINGS, "roomId", roomId, source);

      if (isMountedRef.current) {
        creatingCalendarBookings(response);
      }
    } catch (error) {
      console.log("HTTP call cancelled");
    }
  };

  const creatingCalendarBookings = (response: any[]) => {
    const bookings: ICalendarBooking[] = showCalendarBooking(response);

    addingCleaningBookings(bookings);
  };

  const addingCleaningBookings = (bookings: ICalendarBooking[]) => {
    const cleanings = addCleaning(bookings);
    setTotalBookings(bookings.concat(cleanings));
  };

  const changedLocation = (value: string, rawZones: any[], rawRooms: any[]) => {
    setLocation(value);
    let zones = selectZonesByLocation(rawZones, value);
    setZones(zones);
    setZone(zones[0]?.id);
    //because we changed to the default zone of the location
    changedZone(zones[0]?.id, value, rawRooms)

    loadBookingsByZone(zones[0]?.id);
  };

  const changedZone = (pickedZoneId: string, pickedLocation: string, rawRooms: any[]) => {
    setZone(pickedZoneId);
    let roomsByZone = selectRoomsByZone2(rawRooms, pickedZoneId, pickedLocation);

    setRooms(roomsByZone);
    //Default is ID 0
    setRoom("0");
  };

  const header = isAdmin ? "Reservas" : "Disponibilidad";

  return (
    <>
      <div className="container-fluid pl-4">
        <div className="row mb-4">
          <div className="col-12">
            <h2>{header}</h2>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-3 col-12">
            <select
              className="form-control"
              value={location}
              onChange={({ target: { value } }) =>
                changedLocation(value, rawZones, rawRooms)
              }
            >
              <option value={locationKey.ceca}>{locationKey.ceca}</option>
              <option value={locationKey.medicina}>{locationKey.medicina}</option>
            </select>
          </div>

          <div className="col-sm-4 col-12">
            <select
              className="form-control"
              value={zone}
              onChange={({ target: { value } }) => {
                setZone(value);
                changedZone(value, location, rawRooms)
                loadBookingsByZone(+value);
              }}
            >
              {zones?.map(({ name, id }) => (
                <option key={id} value={id}>
                  {name}
                </option>
              ))}
            </select>
          </div>
          <div className="col-sm-4 col-12">
            <select
              className="form-control"
              value={room}
              onChange={({ target: { value } }) => {
                setRoom(value);
                loadBookingsByRoom(+value);
              }}
            >
              <option id="0" value={"0"}>Todas las salas</option>
              {rooms?.map(({ name, id }) => (
                <option key={id} value={id}>
                  {name}
                </option>
              ))}
            </select>
          </div>


        </div>

        <div className="row mt-4">
          <div className="col-12 whiteDiv">
            <div className="row ">
              <div className="smallSquare mt-1 ml-3 mr-1 bg-lightblue" />
              <span>Pendiente</span>
              <div className="smallSquare mt-1 ml-3 mr-1 bg-blue" />
              <span>Confirmado</span>
              <div className="smallSquare mt-1 ml-3 mr-1 bg-lightgrey" />
              <span>Limpieza</span>
            </div>
            <Calendar
              bookings={totalBookings}
              height={638}
              date={moment().toDate()}
              route={"availability"}
            />
          </div>
        </div>
      </div>
    </>
  );
};
