/* eslint-disable array-callback-return */
import moment from "moment";
import { ICsvBooking } from "../../interfaces/ICsvBooking";
import { switchStatus } from "../status";
import { extractYear, tableLocalTime } from "./dates";

//Excel headers
export const csvHeaders = [
    { label: "Reserva", key: "booking" },
    { label: "Estado", key: "status" },
    { label: "Inicio", key: "startDate" },
    { label: "Fin", key: "endDate" },
    { label: "Instalación", key: "location" },
    { label: "Sala", key: "room" },
    { label: "Usuario", key: "user" },
    { label: "Ip", key: "ip" },
    { label: "Equipo", key: "equipment" },
    { label: "Info. Adicional", key: "additionalInfo" },
    { label: "Estado check-in", key: "checkInStatus" },
  ];

  export const csvTechnicianHeaders = [
    { label: "Reserva", key: "booking" },
    { label: "Inicio", key: "startDate" },
    { label: "Fin", key: "endDate" },
    { label: "Instalación", key: "location" },
    { label: "Sala", key: "room" },
    { label: "Usuario", key: "user" },
    { label: "Equipo", key: "equipment" },
    { label: "Info. Adicional", key: "additionalInfo" },
  ];

export const bookingsToCsvList = (bookings: any[]) => {
  let csv: ICsvBooking[] = [];
  let newObj: any = {};

  let csvEquipment: ICsvBooking[] = [];
  let newObjEquipment: any = {};

  bookings.map((b: any, index) => {
    if (!b.equipments.length) {
      newObj = {
        id:  2*index + new Date().getTime(),
        booking: `#${extractYear(b?.metaBooking.createdAt)}-${b?.metaBooking?.id}`,
        status: switchStatus(b?.metaBooking?.status),
        startDate: tableLocalTime(b?.startDate),
        endDate: tableLocalTime(b?.endDate),
        location: b?.metaBooking?.location,
        room: b?.room?.name,
        user: `${b?.metaBooking?.user?.name} ${b?.metaBooking?.user?.surname1} ${b?.metaBooking?.user?.surname2}`,
        ip: `${b?.metaBooking?.ip?.name} ${b?.metaBooking?.ip?.surname}`,
        equipment: "n/a",
        additionalInfo: b?.additionalInfo,
        checkInStatus: b?.checkInStatus,
        cleaningEndDate: b?.cleaningEndDate,
        createdAt: b?.createdAt,
      };
      csv.push({ ...newObj });
    }
    if (b.equipments.length) {
      b?.equipments.map((c: any, i:number) => {
        newObjEquipment = {
          id: new Date().getTime()/2 + index + i ,
          booking: `#${extractYear(b?.metaBooking.createdAt)}-${b?.metaBooking?.id}`,
          status: switchStatus(b?.metaBooking?.status),
          startDate: tableLocalTime(b?.startDate),
          endDate: tableLocalTime(b?.endDate),
          location: b?.metaBooking?.location,
          room: b?.room?.name,
          user: `${b?.metaBooking?.user?.name} ${b?.metaBooking?.user?.surname1} ${b?.metaBooking?.user?.surname2}`,
          ip: `${b?.metaBooking?.ip?.name} ${b?.metaBooking?.ip?.surname}`,
          equipment: c?.name,
          additionalInfo: b?.additionalInfo,
          checkInStatus: b?.checkInStatus,
          cleaningEndDate: b?.cleaningEndDate,
          createdAt: b?.createdAt,
        };
        csvEquipment.push({ ...newObjEquipment });
      });
    }
  });
  return (csv.concat(csvEquipment));
};

export const OrderCsvByBooking = (csv: ICsvBooking[]) => {
    csv.sort(function (a: any, b: any) {
        if (a.createdAt > b.createdAt ) {
          return 1;
        }
        if (a.createdAt  < b.createdAt ) {
          return -1;
        }
        return 0;
      });
    
      return csv;
}

export  const OrderCsvByStartDate = (booking: ICsvBooking[]) => {
  booking.sort(function (a: any, b: any) {
    if (a.startDate > b.startDate) {
      return 1;
    }
    if (a.startDate < b.startDate) {
      return -1;
    }
    return 0;
  });

  return booking;
};

