import React, { FC, useEffect, useState } from "react";

//middleware
import { useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import { Sidebar } from "../components/Sidebar/Sidebar";

//css
import "../App.css";
//interfaces
import { IStore } from "../interfaces/IStore";
import { IUser } from "../interfaces/IUser";

//views
import { role } from "../utils/roles";
import { Admin } from "./admin";
import { ROUTES } from "../utils/routes";
import { MetaBookings } from "../views/Logged/Common/metaBookings";
import { Bookings } from "../views/Logged/Common/bookings";
import { Availability } from "../views/Logged/Common/availability";
import CheckinRoute from "./mobile";
import ProtectedRoute from "../components/ProtectedRoute/ProtectedRoute";
import { ManageBooking } from "../views/Logged/Common/manageBooking";
import Technician from "./technician";

const LoggedRoutes: FC = () => {
  //prettier-ignore
  let { token, userRole } = useSelector(({ user }: IStore) => user as IUser);
  const history = useHistory();
  const [isCheckin, setIsCheckin] = useState(false);

  const admin = userRole === role.admin;
  const user = userRole === role.user;
  const technician = userRole === role.technician;

  useEffect(() => {
    const { pathname }: any = history.location;
    pathname.includes("check-in") && setIsCheckin(true);
  }, [history.location, isCheckin]);

  return (
    <>
      {isCheckin ? (<CheckinRoute />)
       : (<div className="container-fluid">
            <div className="row wrapper min-vh-100 flex-column flex-md-row ">
             <Sidebar />
             <main className="col bg-faded py-3  mainMargin">
             
               <ProtectedRoute exact path="/" token={token}>
                 <Redirect to={ROUTES.Common.Availability} /> : <Availability/>
               </ProtectedRoute>
             
               <ProtectedRoute exact path={ROUTES.Common.Availability} component={Availability} token={token}/>

           {(admin || user) &&
             <>
              <ProtectedRoute exact path={ROUTES.Common.MetaBookings} component={MetaBookings} token={token}/>
              <ProtectedRoute exact path={[`${ROUTES.Common.MetaBookings}/:id`,`${ROUTES.Common.MetaBookings}/buzon/:id`,]} component={Bookings} token={token} />
              <ProtectedRoute exact path={[ROUTES.Common.ManageBooking, `${ROUTES.Common.ManageBooking}/metabooking/:metaRouteId/booking/:bookingId`]} component={ManageBooking} token={token}/>                                      
              <ProtectedRoute exact path={`${ROUTES.Common.NewBooking}/:metaRouteId`} component={ManageBooking} token={token}/>         
            </> }
            
            { admin && <Admin />}
            
            { technician && <Technician />}
            
            </main>
          </div>
        </div>
      )}
    </>
  );
};

export default LoggedRoutes;
