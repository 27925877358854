import { Modal } from "react-bootstrap";
import { IEquipment } from "../../interfaces/IEquipment";

interface IProps {
  show: boolean;
  onHide: () => void;
  equipments: IEquipment[];
}

export const EquipmentModal = (props: IProps) => {
  const { equipments } = props;
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Instrumental</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <table className="table table-hover text-center">
          <thead className="bg-black text-light">
            <tr>
              <th scope="col">Instrumento</th>
              <th scope="col">Categoría</th>
              <th scope="col">Descripción</th>
            </tr>
          </thead>
          <tbody className="bg-fffff">
            {equipments?.map(
              ({ id, name, equipmentCategory, description }: IEquipment) => (
                <tr key={id}>
                  <td>{name}</td>
                  {/* TODO - Traer la categoría bien cuando Martín termine de editar el endpoint */}
                  <td>{equipmentCategory?.name}</td>
                  <td>{description}</td>
                </tr>
              )
            )}
          </tbody>
        </table>
      </Modal.Body>
    </Modal>
  );
};
