/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

//middleware
import Axios from "axios";
import { faPencilAlt, faPlus, faQrcode } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import QRCode from "qrcode.react";
import { PDFDownloadLink } from "@react-pdf/renderer";

import { IRoomTable } from "../../../../interfaces/IRoom";

//utils
import {
  filter4Multipleselect,
  selectRoomsByZOne,
  selectZonesByLocation,
} from "../../../../utils/auxiliar/roomsFilters";
import { ROUTES } from "../../../../utils/routes";
import { paths } from "../../../../utils/paths";
import { getAll } from "../../../../utils/requests/dynamicReq";
import { RoomQr } from "../../../../components/Qr/RoomQr";
import { cecaURL } from "../../../../utils/constants";
import { locationKey } from "../../../../enum/LocationType";

export const Rooms: FC = () => {
  const history = useHistory();

  const { ROOMS, ZONES, CHECKIN } = paths;

  //selects
  const [rooms, setRooms] = useState<any[]>([]);
  const [zones, setZones] = useState<any[]>([]);
  const [rawZones, setRawZones] = useState([]);

  const [pickedRoom, setPickedRoom] = useState("");
  const [pickedLocation, setPickedLocation] = useState("");
  const [pickedZone, setPickedZone] = useState("");
  const [status, setStatus] = useState("");

  //table
  const [displayRooms, setDisplayRooms] = useState<IRoomTable[]>([]);
  const [allRooms, setAllRooms] = useState<IRoomTable[]>([]);

  const [showPdfLink, setShowPdfLink] = useState(false);
  const isMountedRef = useRef(false);
  const source = Axios.CancelToken.source();

  useEffect(() => {
    isMountedRef.current = true;
    loadData();
    return () => {
      isMountedRef.current = false;
      source.cancel();
    };
  }, []);

  const loadData = async () => {
    try {
      const response = await getAll(ROOMS, source);

      const zones = await getAll(ZONES, source);

      if (isMountedRef.current) {
        composeRooms(response);
        setZones(zones);
        setRawZones(zones);
        setTimeout(() => setShowPdfLink(true), 1000);
      }
    } catch (error) {
      console.log("HTTP call cancelled");
    }
  };

  //-----------------------------

  const composeRooms = (response: any) => {
    let allRooms: IRoomTable[] = [];
    response.map(({ id, zone, name, active }: any) =>
      allRooms.push({
        id,
        room: name,
        zone: zone.name,
        location: zone.location,
        active,
      })
    );
    setDisplayRooms(allRooms);
    setAllRooms(allRooms);
    setRooms(allRooms);
  };

  //-----------------------------

  const cleanFilters = () => {
    setPickedRoom("");
    setPickedZone("");
    setPickedLocation("");
    setStatus("");
    setDisplayRooms(allRooms);
  };

  const filterLocation = (pickedLocation: string) => {
    setPickedLocation(pickedLocation);
    let pickedRoom = "";
    let pickedZone = "";
    setPickedZone(pickedZone);
    setPickedRoom(pickedRoom);
    let filteredZones = selectZonesByLocation(rawZones, pickedLocation);
    setZones(filteredZones);

    filter4Multipleselect(pickedLocation, pickedZone, pickedRoom, status, setDisplayRooms, allRooms);
  };

  const filterZone = (pickedZone: string) => {
    setPickedZone(pickedZone);

    let pickedRoom = "";
    setPickedRoom(pickedRoom);
    let filteredRooms = selectRoomsByZOne(allRooms, pickedZone, pickedLocation);
    setRooms(filteredRooms);

    filter4Multipleselect(pickedLocation, pickedZone, pickedRoom, status, setDisplayRooms, allRooms);
  };

  const filterRooms = (pickedRoom: string) => {
    setPickedRoom(pickedRoom);
    filter4Multipleselect(pickedLocation, pickedZone, pickedRoom, status, setDisplayRooms, allRooms);
  };

  const filterStatus = (status: string) => {
    setStatus(status);
    filter4Multipleselect(pickedLocation, pickedZone, pickedRoom, status, setDisplayRooms, allRooms);
  };

  const downloadQR = (id: any) => {
    const canvas = document?.getElementById(id) as HTMLCanvasElement;
    const pngUrl = canvas?.toDataURL("image/png")?.replace("image/png", "image/octet-stream");
    return pngUrl;
  };

  return (
    <>
      <div className="container-fluid pl-4">
        <div className="row mb-4">
          <div className="col-12">
            <h2>Salas</h2>
          </div>
        </div>
        <div className="row">
          <select
            className=" col form-control selectMultiple input mr-2 mt-2"
            style={{ width: "9rem" }}
            data-spy="scroll"
            value={pickedLocation}
            onChange={({ target: { value } }) => filterLocation(value)}
          >
            <option value={""}>Centro</option>
            <option value={locationKey.ceca}>{locationKey.ceca}</option>
            <option value={locationKey.medicina}>{locationKey.medicina}</option>
          </select>
          <select
            className="  col form-control selectMultiple input mr-2 mt-2"
            style={{ width: "9rem" }}
            data-spy="scroll"
            disabled={!pickedLocation}
            value={pickedZone}
            onChange={({ target: { value } }) => filterZone(value)}
          >
            <option value={""}>Zona</option>

            {zones.map(({ name, id }) => (
              <option key={id} value={name}>
                {name}
              </option>
            ))}
          </select>
          <select
            className=" col form-control selectMultiple input mr-2 mt-2"
            style={{ width: "9.2rem" }}
            disabled={!pickedZone}
            value={pickedRoom}
            onChange={({ target: { value } }) => filterRooms(value)}
          >
            <option value={""}>Todas las salas</option>
            {rooms.map(({ room, id }) => (
              <option key={id} value={room}>
                {room}
              </option>
            ))}
          </select>
          <select
            className="col form-control selectMultiple input mr-2 mt-2"
            style={{ width: "9rem" }}
            value={status}
            onChange={({ target: { value } }) => filterStatus(value)}
          >
            <option value={""}>Habilitada</option>
            <option value={1}>Sí</option>
            <option value={0}>No</option>
          </select>
          <button className="button-search mt-2 mr-5" onClick={cleanFilters}>
            Quitar filtros
          </button>

          <button className="button-search mt-2 " onClick={() => history.push(`${ROUTES.Admin.Rooms.create}`)}>
            <FontAwesomeIcon className=" mr-2 " icon={faPlus} />
            Nueva Sala
          </button>
        </div>

        <div className="row mt-4">
          <div className="col-12 whiteDiv overFlowAuto">
            <table className="table table-hover text-center">
              <thead className="bg-black text-light">
                <tr>
                  <th scope="col">Sala</th>
                  <th scope="col">Instalación</th>
                  <th scope="col">Zona</th>
                  <th scope="col">Qr Check-in</th>
                  <th scope="col">Habilitada</th>
                  <th scope="col">Editar</th>
                </tr>
              </thead>
              <tbody className="bg-fffff">
                {displayRooms?.map(({ id, room, location, zone, active }: IRoomTable) => (
                  <tr key={id}>
                    <td>
                      <strong>{room}</strong>{" "}
                    </td>

                    <td>{location}</td>
                    <td>{zone} </td>
                    <td>
                      {showPdfLink && (
                        <PDFDownloadLink
                          document={
                            <RoomQr
                              id={id + 1!}
                              location={location}
                              room={room}
                              qrCodeData={downloadQR(id.toString())}
                            />
                          }
                          fileName={`${location}-${room}`}
                        >
                          {({ blob, url, loading, error }) => (
                            <button className="qr ml-1" style={{ width: "30px" }}>
                              {loading ? (
                                <span className="spinner-border spinner-border-sm" />
                              ) : (
                                <FontAwesomeIcon icon={faQrcode} />
                              )}
                            </button>
                          )}
                        </PDFDownloadLink>
                      )}

                      <div style={{ display: "none" }}>
                        <QRCode
                          id={`${id}`}
                          value={`${cecaURL}${CHECKIN}/${id}`}
                          size={290}
                          level={"H"}
                          includeMargin={true}
                        />
                      </div>
                    </td>
                    <td>{!!active ? "Sí" : "No"}</td>
                    <td className="pointer" onClick={() => history.push(`${ROUTES.Admin.Rooms.all}/${id}`)}>
                      <FontAwesomeIcon color="#0099cc" icon={faPencilAlt} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};
