import { Button, Modal } from "react-bootstrap";

interface IProps {
  show: boolean;
  onHide: () => void;
  oldPassword: string;
  setOldPassword: any;
  newPassword: string;
  setNewPassword: any;
  repeatNewPass: string;
  setRepeatNewPass: any;
  handleSubmitNewPassword: () => void;
}

export const PasswordModal = (props: IProps) => {
  const {
    setOldPassword,
    setNewPassword,
    setRepeatNewPass,
    oldPassword,
    newPassword,
    repeatNewPass,
    onHide,
    handleSubmitNewPassword,
  } = props;

  const notMatch = newPassword !== repeatNewPass;
  const message = "Las contraseñas no coinciden";
  const disabled = !oldPassword || !newPassword || !repeatNewPass || (newPassword !== repeatNewPass);
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Cambiar Contraseña
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container">
          <div className="row">
            <div className="col-12 text-center text-danger">
              {notMatch && <span>{message}</span>}
            </div>
          </div>
          <div className="row">
            <div className="col">
              <input
                className="form-control"
                type="password"
                placeholder="*Contraseña Actual"
                  value={oldPassword}
                  onChange={({ target: { value } }) => {
                    setOldPassword(value);
                  }}
              />
            </div>
          </div>
          <div className="row mt-2">
            <div className="col">
              <input
                className="form-control"
                type="password"
                placeholder="*Nueva Contraseña"
                  value={newPassword}
                  onChange={({ target: { value } }) => {
                    setNewPassword(value);
                  }}
              />
            </div>
          </div>
          <div className="row mt-2">
            <div className="col">
              <input
                className="form-control"
                type="password"
                placeholder="*Repetir Contraseña"
                  value={repeatNewPass}
                  onChange={({ target: { value } }) => {
                    setRepeatNewPass(value);
                  }}
              />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cerrar
        </Button>
        <Button variant="primary" disabled={disabled}  style={{
              cursor: disabled ? "not-allowed" : "",
            }}
            onClick={handleSubmitNewPassword}>
          Guardar Cambio
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
