/* eslint-disable eqeqeq */
import { IAnimal } from "../../interfaces/IAnimal";
import { IRoomTable } from "../../interfaces/IRoom";
import { IZone } from "../../interfaces/IZone";

interface IRoomLOCAL {
  id: number;
  animals: IAnimal[];
  zone: IZone;
}

export const filter4Multipleselect = (
  location: string,
  zone: string,
  room: string,
  status: boolean | string,
  setFilter: any,
  allRooms: IRoomTable[]
) => {
  let filteredData = allRooms.filter((e) => {
    return (
      (location === "" || e.location === location) &&
      (zone === "" || e.zone === zone) &&
      (room === "" || e.room === room) &&
      (status === "" || e.active == status)
    );
  });
  setFilter(filteredData);
};

export const removeRepeatedZones = (response: any) => {
  const zones = response?.filter(
    (r: any, i: any, res: any) => res.findIndex((o: any) => o.name === r.name) === i
  );
  return zones;
};

export const removeRepeatedLocations = (response: any) => {
  const locations = response?.filter(
    (r: any, i: any, res: any) =>
      res.findIndex((o: any) => o.location === r.location) === i
  );
  return locations;
};

export const selectZonesByLocation = (response: any, pickedLocation: string) => {
  const zones = response?.filter(({ location }: any) => location === pickedLocation);

  return zones;
};

// This works with the IRoomTable object

export const selectRoomsByZOne = (response: any, pickedZone: string, pickedLocation: string) => {

  const rooms = response?.filter(({ zone, location }: any) => (zone === pickedZone && location === pickedLocation));

  return rooms;
};

// This works with the regular room object
export const selectRoomsByZone2 = (response: any, pickedZone: string, pickedLocation: string) => {

  const rooms = response?.filter((room: any) =>(room.zone.id == pickedZone && room.zone.location === pickedLocation));

  return rooms;
};

export const selectRoomsByLocation = (response: any, pickedLocation: string) => {
  const rooms = response?.filter(
    ({ zone }: any) => zone?.location === pickedLocation
  );
  return rooms;
};

export const filterRooms = (
  rooms: IRoomLOCAL[],
  animalId: number,
  zoneId: number
) => {
  let filteredRooms: IRoomLOCAL[] = [];

  let roomsByZone = rooms.filter((room) => +room.zone.id! === +zoneId);
  //prettier-ignore
  roomsByZone.map((room) =>room.animals.map(({ id }) => +id! === +animalId && filteredRooms.push(room)));

  return filteredRooms;
};
