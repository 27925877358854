import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import logo from "./FilaSuperior.png";

// Create styles
const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 35,
  },
  title: {
    fontSize: 20,
    fontWeight: 700,
    textAlign: "center",
    fontStyle: "bold",
  },
  subtitle: {
    fontSize: 18,
    textAlign: "center",
    marginBottom: 20,
  },
  bulletPoint: {
    fontSize: 12,
    marginBottom: 5,
    marginLeft: 12,
  },
  text: {
    margin: 8,
    fontSize: 12,
    textAlign: "center",
  },
  logo: {
    marginVertical: 12,
    width: 600,
  },
  qr: {
    marginVertical: 25,
    marginLeft: 198,
    justifyContent: "center",
    width: 200,
  },
  header: {
    fontSize: 12,
    marginBottom: 2,
    textAlign: "center",
    color: "grey",
  },
  separationLine: {
    borderTop: "black",
    borderTopColor: "black",
    borderTopWidth: 2,
    marginLeft: 12,
  },
  row: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  column: {
    flexDirection: "column",
    width: "20%",
  },
  columnDark: {
    flexDirection: "column",
    width: "100%",
    backgroundColor: "#003366",
    height: 12,
  },
  columnBlue: {
    flexDirection: "column",
    width: "100%",
    backgroundColor: "#0099cc",
    paddingTop: 12,
    height: 12,
  },
  columnPink: {
    flexDirection: "column",
    width: "100%",
    backgroundColor: "#cc0066",
    paddingTop: 12,
    height: 12,
  },
});

// Create Document Component
export const RoomQr = ({ id, qrCodeData, location, room }: any) => {
  return (
    <Document>
      {id && qrCodeData && logo && (
        <>
          <Page style={styles.body} size="A4">
            <View>
              <View style={styles.row} wrap>
                <View style={styles.column}>
                  {logo && (
                    <Image style={styles.logo} src={logo} cache={false} />
                  )}
                </View>
              </View>
              <Text style={[styles.title, { marginTop: "35px" }]}>
                Centro Experimentación Animal
              </Text>
              <Text style={styles.title}>Universidad de Málaga</Text>

              <Text style={[styles.subtitle, { marginTop: "25px" }]}>
                {location}. {room}
              </Text>

              <View style={styles.column}>
                {qrCodeData && (
                  <Image style={styles.qr} src={qrCodeData} cache={false} />
                )}
              </View>
              <View style={styles.row} wrap>
                <Text style={[styles.text, { marginTop: "29px" }]}>
                  Lee el QR para hacer check-in de tu reserva y confirmar tu
                  asistencia.
                </Text>
              </View>
              <View style={styles.row} wrap>
                <Text style={styles.text}>
                  Para cualquier duda o consulta contacte con administración.
                </Text>
              </View>

              <View>
                <View style={[styles.row, { marginTop: "125px" }]} wrap>
                  <View style={styles.columnDark}></View>
                </View>
                <View style={[styles.row, { marginTop: "3px" }]} wrap>
                  <View style={styles.columnBlue}></View>
                </View>

                <View style={[styles.row, { marginTop: "3px" }]} wrap>
                  <View style={styles.columnPink}></View>
                </View>
              </View>
            </View>
          </Page>
        </>
      )}
    </Document>
  );
};
