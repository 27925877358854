/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

//css
import "./../Sidebar.css";

//middleware
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import {
  faSignOutAlt,
  faCalendar,
  faUserCog,
  faVial,
} from "@fortawesome/free-solid-svg-icons";
import { ROUTES } from "../../../utils/routes";

interface IProps {
  logout: () => void;
  setShowPassword: any;
}

export const TechnicianSidebar = (props: IProps) => {
  //prettier-ignore
  const {logout, setShowPassword} = props;

  return (
    <>
      <Nav className="navbar navbar-expand-md navbar-dark  align-items-start flex-md-column flex-row bg-blue ">
        <a
          // href="javascript:void(0);"
          href="void(0);"
          className="navbar-toggler"
          data-toggle="collapse"
          data-target="#sidebarID"
        >
          <span className="navbar-toggler-icon"></span>
        </a>
        <div className="collapse navbar-collapse sidebar" id="sidebarID">
          <ul className="flex-column navbar-nav w-100 justify-content-between">
            <li>
              <Nav.Item>
                <NavLink className="nav-link pl-0" to={ROUTES.Technician.DailyEquipments}>
                  <FontAwesomeIcon className="iconClass mr-3" icon={faVial} />
                  <span className="">Instrumental</span>
                </NavLink>
              </Nav.Item>
            </li>
            <li>
              <Nav.Item>
                <NavLink className="nav-link pl-0" to={ROUTES.Technician.WeeklyBookings}>
                  <FontAwesomeIcon className="iconClass mr-3" icon={faCalendar} />
                  <span className="">Reservas</span>
                </NavLink>
              </Nav.Item>
            </li>
            <li className="nav-item responsiveLogout" onClick={logout}>
              <a className="nav-link pl-0" href="#">
                <FontAwesomeIcon className="iconClass mr-3" icon={faSignOutAlt} />
                <span className="">Cerrar sesión</span>
              </a>
            </li>
            <li
              className="nav-item responsiveLogout"
              onClick={() => setShowPassword(true)}
            >
              <a className="nav-link pl-0" href="#">
                <FontAwesomeIcon className="iconClass mr-3" icon={faUserCog} />
                <span className="">Contraseña</span>
              </a>
            </li>
          </ul>
        </div>
      </Nav>
    </>
  );
};
