//middleware
import { FC } from "react";
import { useSelector } from "react-redux";
import ProtectedRoute from "../components/ProtectedRoute/ProtectedRoute";
import { IStore } from "../interfaces/IStore";
import { IUser } from "../interfaces/IUser";

//views
import { ROUTES } from "../utils/routes";
import { Equipment } from "../views/Logged/Admin/Equipment/Equipment";
import { ManageEquipment } from "../views/Logged/Admin/Equipment/ManageEquipment";
import { Inbox } from "../views/Logged/Admin/Inbox";
import { ManageIps } from "../views/Logged/Admin/Ips/ManageIps";
import { ManageRoom } from "../views/Logged/Admin/Rooms/ManageRoom";
import { Rooms } from "../views/Logged/Admin/Rooms/Rooms";
import { ManageUsers } from "../views/Logged/Admin/Users/ManageUsers";
import { Users } from "../views/Logged/Admin/Users/Users";

export const Admin: FC = () => {
  //prettier-ignore
  let { token } = useSelector(({ user }: IStore) => user as IUser);
  // prettier-ignore
  return (
    <>
    {/* <ProtectedRoute exact path="/" token={token}>
    <Redirect to={ROUTES.Admin.Inbox.all} /> : <Inbox/>
    </ProtectedRoute> */}
    
    <ProtectedRoute exact path={ROUTES.Admin.Users.all} component={Users} token={token} />
    <ProtectedRoute exact path={[ROUTES.Admin.Users.create, ROUTES.Admin.Users.update]} component={ManageUsers} token={token} />

    <ProtectedRoute exact path={ROUTES.Admin.Equipment.all} component={Equipment} token={token} />
    <ProtectedRoute exact path={[ROUTES.Admin.Equipment.create, ROUTES.Admin.Equipment.update]} component={ManageEquipment} token={token} />

    <ProtectedRoute exact path={ROUTES.Admin.Rooms.all} component={Rooms} token={token} />
    <ProtectedRoute exact path={[ROUTES.Admin.Rooms.create, ROUTES.Admin.Rooms.update]} component={ManageRoom} token={token} />

    <ProtectedRoute exact path={ROUTES.Admin.Ips.all} component={ManageIps} token={token} />

    <ProtectedRoute exact path={ROUTES.Admin.Inbox.all} component={Inbox} token={token} />
 
    </>
  );
};
