/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef } from "react";

//middleware
import Axios from "axios";
import { IInbox } from "../../interfaces/IInbox";
import { useHistory } from "react-router";
import { ROUTES } from "../../utils/routes";
import moment from "moment";
import { switchStatus } from "../../utils/status";


interface IProps {
  inbox: IInbox[];
  index: number;
}

export const InboxList = ({ inbox, index }: IProps) => {
  const isMountedRef = useRef(false);
  const source = Axios.CancelToken.source();
  const history = useHistory();

  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
      source.cancel();
    };
  }, []);

  return (
    <>
      <table className="table table-hover text-center">
        <thead className="bg-black text-light">
          <tr>
            <th scope="col">Reserva</th>
            {/* <th scope="col">Fecha Inicio</th>
            <th scope="col">Fecha Fin</th> */}
            <th scope="col">Estado</th>
            <th scope="col">Instalación</th>
            <th scope="col">Datos Inv.</th>
            <th scope="col">Correo</th>
            <th scope="col">Teléfono</th>
            <th scope="col">Info Reserva</th>
          </tr>
        </thead>
        <tbody className="bg-fffff">
          {inbox?.slice(0, index)?.map(
            //prettier-ignore
            ({id, status, location, user}: IInbox) => (
                      <tr key={id}>
                        <td><strong>{`#${moment().year()}-${id}`} </strong></td>
                        {/* <td>{startDate}</td>
                        <td>
                          {endDate}
                        </td> */}
                        <td>{switchStatus(status)} </td>
                        <td>{location} </td>
                        <td>{`${user.name} ${user.surname1} ${user.surname2}`}</td>
                        <td>{user?.email} </td>
                        <td>{user?.phone}</td>
                        <td className="pointer c-lightblue" 
                          onClick={() => history.push(`${ROUTES.Common.MetaBookings}/buzon/${id}`)}
                          >
                         <strong>Ver</strong>
                         </td>
                      </tr>
                    )
          )}
        </tbody>
      </table>
    </>
  );
};
