import { Modal } from "react-bootstrap";
import { IUser } from "../../interfaces/IUser";

interface IProps {
  show: boolean;
  onHide: () => void;
  users: IUser[];
  externaluser1: string;
  externaluser2: string;
}

export const UsersModal = (props: IProps) => {
  const { users, externaluser1, externaluser2 } = props;
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Acompañante/s</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <table className="table table-hover text-center">
          <thead className="bg-black text-light">
            <tr>
              <th scope="col">Nombre completo</th>
            </tr>
          </thead>
          <tbody className="bg-fffff">
            {users?.map(({ id, name, surname1, surname2 }: IUser) => (
              <tr key={id}>
                <td>
                  {name} {surname1} {surname2}
                </td>
              </tr>
            ))}
            {!!externaluser1 && (
              <tr>
                <td>
                  <i>Usuario externo: {externaluser1} </i>
                </td>
              </tr>
            )}
            {!!externaluser2 && (
              <tr>
                <td>
                  <i>Usuario externo: {externaluser2} </i>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </Modal.Body>
    </Modal>
  );
};
