/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";

//middleware
import Axios from "axios";

//images
import logo from "../../assets/images/MARCA_UNIVERSIDAD_BLANCO_TRANS_1.png";
import { confirm } from "../../utils/requests/dynamicReq";
import Swal from "sweetalert2";
import { SwalCheckInError } from "../../utils/constants";
import { paths } from "../../utils/paths";
import { useParams } from "react-router-dom";

export const Checkin: any = () => {
  const { id }: any = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const isMountedRef = useRef(false);
  const source = Axios.CancelToken.source();
  const { BOOKINGROOM } = paths;

  useEffect(() => {
    isMountedRef.current = true;
    // const response = await getAvailability(rooomId);
    // setAvailability(response);
    return () => {
      //quitar fecha y sala cuando el componente se desmonte
      isMountedRef.current = false;
      source.cancel();
    };
  }, []);

  const handleSubmit = async () => {
    setIsLoading(true);

    confirm(BOOKINGROOM, id, {}, source)
      .then(async (response) => {
        setIsLoading(false);
        if (response?.checkInStatus === "On_time")
          Swal.fire({ icon: "success", text: `Check-in realizado correctamente.` });
        if (response?.checkInStatus === "Delayed")
          Swal.fire({
            icon: "warning",
            iconColor: "yellow",
            text: `El check-in ha sido registrado con más de 30 min de retraso, contacta con administración para solucionar el problema. Se te enviará un correo de recordatorio.`,
          });
      })
      .catch(() => {
        setIsLoading(false);
        Swal.fire({ icon: "error", text: SwalCheckInError });
      });
  };

  return (
    <>
      {/* <div className="container-fluid">
    <div className="row mb-5"> */}
      <div className="loginContainer mobileMargin">
        <div
          className="cardLogin"
          onKeyPress={({ key }) => {
            if (key === "Enter") {
              handleSubmit();
            }
          }}
        >
          <img className="loginLogo" src={logo} alt="" />

          <button className="loginButton mt-4" onClick={handleSubmit}>
            {!isLoading ? (
              <h5>Check-in</h5>
            ) : (
              <span className="spinner-border spinner-border-sm" />
            )}
          </button>
        </div>
      </div>
      {/* </div>
      </div> */}
    </>
  );
};
