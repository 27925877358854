/* eslint-disable react-hooks/exhaustive-deps */

//middleware
import { formatDate, formatTime } from "../../utils/auxiliar/dates";

import { ICsvBooking } from "../../interfaces/ICsvBooking";

interface IProps {
  bookings: ICsvBooking[];
  index: number;
}

export const EquipmentList = ({ bookings, index }: IProps) => {
  return (
    <>
      <table className="table table-hover text-center">
        <thead className="bg-black text-light">
          <tr>
            <th scope="col">Instrumental</th>
            <th scope="col">Código reserva</th>
            <th scope="col">Fecha</th>
            <th scope="col">Horario</th>
            <th scope="col">Sala</th>
            <th scope="col">Datos Inv.</th>
          </tr>
        </thead>
        <tbody className="bg-fffff">
          {bookings?.slice(0, index)?.map(
            ({
              id,
              equipment,
              booking,
              startDate,
              endDate,
              room,
              user,
            }: ICsvBooking) => (
              <tr key={id}>
                <td>{equipment}</td>
                <td>
                  {booking}
                </td>
                <td>{formatDate(startDate)}</td>
                <td>{`${formatTime(startDate)} - ${formatTime(endDate)}`}</td>
                <td>{room}</td>
                <td>{user}</td>
              </tr>
            )
          )}
        </tbody>
      </table>
    </>
  );
};
