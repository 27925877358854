import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const borderColor = "#242424";
const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    borderBottomColor: "#0099cc",
    backgroundColor: "#0099cc",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    textAlign: "center",
    fontStyle: "bold",
    flexGrow: 1,
  },
  bookingNo: {
    width: "12%",
    textAlign: "left",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingLeft: 8,
    fontSize: 10,
    fontStyle: "bold",
  },
  investigator: {
    width: "33%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "left",
    paddingLeft: 8,
    fontSize: 10,
  },
  location: {
    width: "12%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "left",
    paddingLeft: 8,
    fontSize: 10,
  },
  day: {
    width: "11%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "left",
    paddingLeft: 8,
    fontSize: 10,
  },
  date: {
    width: "14%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "left",
    paddingLeft: 8,
    fontSize: 10,
  },
  room: {
    width: "18%",
    textAlign: "left",
    paddingLeft: 8,
    fontSize: 10,
  },
});



export const BookingsTableHeader2 = () => {
  return (
    <View style={styles.container}>
      <Text style={styles.bookingNo}>Reserva</Text>
      <Text style={styles.investigator}>Investigador</Text>
      <Text style={styles.location}>Centro</Text>
      <Text style={styles.day}>Día</Text>
      <Text style={styles.date}>Hora</Text>
      <Text style={styles.room}>Sala</Text>
    </View>
  );
};
