import React from "react";
import { Button, Form, Modal } from "react-bootstrap";

interface IProps {
  show: boolean;
  onHide: () => void;
  name: string;
  setName: any;
  surname: string;
  setSurname: any;
  active: boolean;
  setActive: any;
  handleSubmit: () => void;
}

export const CreateIp = (props: IProps) => {
  const {
    name,
    setName,
    surname,
    setSurname,
    active,
    setActive,
    onHide,
    handleSubmit,
  } = props;

  const notValid = !name || !surname;
  const message = "Por favor, rellena todos los campos";

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Nuevo IP
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container">
          <div className="row">
            <div className="col-12 text-center text-danger">
              {notValid && <span>{message}</span>}
            </div>
          </div>
          <div className="row">
            <div className="col-8">
            <h6>*Nombre</h6>
                  <input
                    type="text"
                    className="form-control"
                    value={name}
                    onChange={({ target: { value } }) => {
                      setName(value);
                    }}
                  />
            </div>
            <div className="col-4">
            <h6>Habilitada</h6>
                  <Form>
                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      label={active ? "Sí" : "No"}
                      checked={active!}
                      onChange={() => {
                        setActive(!active);
                      }}
                    />
                  </Form>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col">
            <h6>*Apellidos</h6>
                  <input
                    type="text"
                    className="form-control"
                    value={surname}
                    onChange={({ target: { value } }) => {
                      setSurname(value);
                    }}
                  />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cerrar
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Guardar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
