import { combineReducers } from "redux";
import { IStore } from "../../interfaces/IStore";
import user from "./userReducer";
import booking from "./bookingReducer";

export default combineReducers<IStore>({
  user,
  booking
});

