import React, {Fragment} from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';
import { ICsvBooking } from '../../../interfaces/ICsvBooking';
import { formatTime } from '../../../utils/auxiliar/dates';
import moment from 'moment';

const borderColor = '#242424'
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        borderBottomColor: '#242424',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
    },
    bookingNo: {
      width: "12%",
      textAlign: "left",
      borderRightColor: borderColor,
      borderRightWidth: 1,
      paddingLeft: 8,
      fontSize: 10,
      fontStyle: "bold",
    },
    investigator: {
      width: "27%",
      borderRightColor: borderColor,
      borderRightWidth: 1,
      textAlign: "left",
      paddingLeft: 8,
      fontSize: 10,
    },
    location: {
      width: "11%",
      borderRightColor: borderColor,
      borderRightWidth: 1,
      textAlign: "left",
      paddingLeft: 8,
      fontSize: 10,
    },
    day: {
      width: "11%",
      borderRightColor: borderColor,
      borderRightWidth: 1,
      textAlign: "left",
      paddingLeft: 8,
      fontSize: 10,
    },
    date: {
      width: "14%",
      borderRightColor: borderColor,
      borderRightWidth: 1,
      textAlign: "left",
      paddingLeft: 8,
      fontSize: 10,
    },
    room: {
      width: "19%",
      borderRightColor: borderColor,
      borderRightWidth: 1,
      textAlign: "left",
      paddingLeft: 8,
      fontSize: 10,
    },
    type: {
      width: "6%",
      textAlign: "left",
      paddingLeft: 8,
      fontSize: 10,
    },
  });

  interface IProps {
    bookings: ICsvBooking[];
  }
  

export const BookingsTableRow3 = ({bookings}:IProps) => {
    // console.log(bookings)
    const rows = bookings.map( ({id, booking, location, startDate, endDate, cleaningEndDate, room, user}:any) => 
        <View style={styles.row} key={id.toString()}>
            <Text style={styles.bookingNo}>{booking}</Text>
            <Text style={styles.investigator}>{user}</Text>
            <Text style={styles.location}>{location}</Text>
            <Text style={styles.day}>{`${moment(startDate).format("DD-MM-YY")}`}</Text>
            <Text style={styles.date}>{`${formatTime(startDate)} - ${formatTime(endDate)}`}</Text>
            <Text style={styles.room}>{room}</Text>
            <Text style={styles.type}></Text>

        </View>
    )
    return (<Fragment>{rows}</Fragment> )
};
  