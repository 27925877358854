/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";

//middleware
import Axios from "axios";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { PDFDownloadLink } from "@react-pdf/renderer";

import { More } from "../../../components/More/More";
import { paths } from "../../../utils/paths";
import { getAll, getBetweenTwoDates } from "../../../utils/requests/dynamicReq";
import { EquipmentList } from "../../../components/EquipmentList/EquipmentList";
import {
  bookingsToCsvList,
  OrderCsvByStartDate,
} from "../../../utils/auxiliar/csv";
import { ICsvBooking } from "../../../interfaces/ICsvBooking";
import { IRoomTable } from "../../../interfaces/IRoom";
import { TechnicianPdf } from "../../../components/Pdf/TechnicianPdf";
import { locationKey } from "../../../enum/LocationType";
import { in7Days } from "../../../utils/constants";



export const DailyEquipments: any = () => {
  const { BOOKINGSBYDATE, ROOMS } = paths;

  const isMountedRef = useRef(false);
  const source = Axios.CancelToken.source();

  const [displayBookings, setDisplayBookings] = useState<ICsvBooking[]>([]);
  const [bookingsByEquipment, setBookingsByEquipment] = useState<
    ICsvBooking[]
  >();
  const [rooms, setRooms] = useState<IRoomTable[]>([]);

  //table
  const [allRooms, setAllRooms] = useState<IRoomTable[]>([]);
  const [location, setLocation] = useState("");
  const [room, setRoom] = useState("");

  const [showPdfLink, setShowPdfLink] = useState(false);
  const [index, setIndex] = useState(10);
  const [isLoading, setIsLoading] = useState(false);

  let startDate = moment(new Date()).format("YYYY-MM-DD");
  let endDate = moment(new Date(in7Days)).format("YYYY-MM-DD");



  useEffect(() => {
    setIsLoading(true);
    isMountedRef.current = true;
    loadData();
    return () => {
      isMountedRef.current = false;
      source.cancel();
    };
  }, []);

  const loadData = async () => {
    try {
      const response = await getBetweenTwoDates(
        BOOKINGSBYDATE,
        "fromDate",
        startDate,
        "toDate",
        endDate,
        source
      );

      const rooms = await getAll(ROOMS, source);

      if (isMountedRef.current) {
        let bookingsByEquipment = bookingsToCsvList(response);
        setBookingsByEquipment(OrderCsvByStartDate(bookingsByEquipment));
        setDisplayBookings(OrderCsvByStartDate(bookingsByEquipment));

        setTimeout(() => setShowPdfLink(true), 1000);

        composeRooms(rooms);
        setIsLoading(false);
      }
    } catch (error) {
      if (isMountedRef.current) {
        setIsLoading(false);
        console.log("HTTP call cancelled");
      }
    }
  };

  const refreshIndex = (number: number) => {
    //TODO - Chequear que esta función "funciona"
    setIndex(number);
    displayBookings?.slice(0, number);
  };

  //-----------------------------

  const composeRooms = (response: any) => {
    let allRooms: IRoomTable[] = [];
    response.map(({ id, zone, name, active }: any) =>
      allRooms.push({
        id,
        room: name,
        zone: zone.name,
        location: zone.location,
        active,
      })
    );
    setAllRooms(allRooms);
    setRooms(allRooms);
  };

  const filterLocation = (location: string) => {
    setLocation(location);
      let room = "";
      setRoom(room);
      setRooms(displayByLocation(allRooms, location));
      multipleFilter(room, location);
   
  };

  const displayByLocation = (response: any, pickedLocation: string) => {
    const rooms = response?.filter(
      ({ location }: any) => location === pickedLocation
    );
    return rooms;
  };

  const filterRoom = (room: string) => {
    setRoom(room);
    multipleFilter(room, location);
  };

  const multipleFilter = (pickedRoom: string, pickedLocation: string) => {
    setBookingsByEquipment(
      displayBookings.filter(({ room, location }: ICsvBooking) => {
        return (
          (pickedRoom === "" || room === pickedRoom) &&
          (pickedLocation === "" || location === pickedLocation)
        );
      })
    );
  };

  return (
    <>
      <div className="container-fluid pl-4">
        <div className="row">
          <div className="col-sm-10 col-12 ">
            <h5>Instrumental</h5>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-3 col-12">
            <select
              className="form-control"
              value={location}
              onChange={({ target: { value } }) => filterLocation(value)}
            >
              <option value={""}>Instalación</option>
              <option value={locationKey.ceca}>{locationKey.ceca}</option>
                  <option value={locationKey.medicina}>{locationKey.medicina}</option>
            </select>
          </div>
          <div className="col-sm-3 col-12">
            <select
              className="form-control"
              disabled={!location}
              value={room}
              onChange={({ target: { value } }) => filterRoom(value)}
            >
              <option value={""}>Salas</option>
              {rooms.map(({ room, id }) => (
                <option key={id} value={room}>
                  {room}
                </option>
              ))}
            </select>
          </div>
          <div className="col-5" />
          <div className="col-sm-1 col-12">
            {showPdfLink && (
              <PDFDownloadLink
                document={<TechnicianPdf bookings={bookingsByEquipment!} startDate={startDate!} endDate={endDate!} room={room} location={location} />}
                fileName={`Reservas-${startDate}-a-${endDate}`}
              >
                {({ blob, url, loading, error }) => (
                  <>
                   {loading ? (
                    <span className="spinner-border spinner-border-sm" />
                  ) : (
                  <button
                    className="smallBtn ml-1 mr-1"
                    style={{ width: "40px" }}
                  >
                   
                      <FontAwesomeIcon
                        color="red"
                        style={{ fontSize: "24px" }}
                        icon={faFilePdf}
                      />
                 
                  </button>
                  )}
                  </>
                )}
              </PDFDownloadLink>
            )}
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-12 whiteDiv overFlowAuto">
            <EquipmentList bookings={bookingsByEquipment!} index={index} />
            <More
              data={bookingsByEquipment!}
              displayData={bookingsByEquipment!}
              isLoading={isLoading}
              setIndex={refreshIndex}
              index={index}
            />
          </div>
        </div>
      </div>
    </>
  );
};
