export const validate = (u: any) => {
  let aux = "";
  if (!u.name) aux = "Por favor escriba el nombre del usuario";
  if (!u.surname1) aux = "Por favor escriba el primer apellido del usuario";
  if (!u.email) aux = "Por favor escriba el email del usuario";
  if (!validateEmail(u.email)) aux = "Por favor escriba un email válido";
  if (!u.phone) aux = "Por favor escriba el teléfono del usuario";
  if (!u.userRole) aux = "Por favor asigne un rol al usuario";
  if (!u.ipId) aux = "Por favor asigne un IP al usuario";
  return aux;
};

const validateEmail = (email: string) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};
