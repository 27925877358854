interface IProps {
  data: any[];
  displayData: any[];
  setIndex(number: number): void;
  index: number;
  isLoading: boolean;
}

export const More = ({ data, displayData, isLoading, setIndex, index }: IProps) => {
  return (
    <>
      {isLoading && (
        <div className="d-flex justify-content-center">
          <div className="spinner-border mt-5" role="status" />
        </div>
      )}
      {displayData?.length > index && (
        <div className="d-flex justify-content-center mt-5">
          <span className="pointer" onClick={() => setIndex(index + 10)}>
            Ver más
          </span>
        </div>
      )}
      {!displayData?.length && !isLoading && (
        <div className="d-flex justify-content-center mb-3">
          <h5>-No hay resultados-</h5>
        </div>
      )}
    </>
  );
};
