/* eslint-disable array-callback-return */
import { IMetaBooking } from "../../interfaces/IMetaBooking";

export const hideCompletedIrregular = (metaBookings: IMetaBooking[]) => {
  metaBookings.map(({  status }, index: number) => {
    if (status === "completed_irregular") {
      metaBookings[index].status = "completed";
    }
  });
  return metaBookings;
};
